import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/theme';
import { Link } from 'gatsby';

interface SubcategoryContainerProps extends ThemedProps {
    selected: boolean;
}

const SubcategoryLink = styled(Link)<SubcategoryContainerProps>`
    display: block;
    padding-left: 1em;
    border-radius: ${({ theme }: SubcategoryContainerProps) => theme.borders.radiusSmall};
    position: relative;
    color: ${({ theme }: ThemedProps) => theme.colors.black};

    &::before {
        display: block;
        position: absolute;
        top: 0;
        content: '';
        left: calc(0.5em - 2px);
        width: 4px;
        height: 100%;
        background-color: ${({ theme, selected }: SubcategoryContainerProps) =>
            selected ? theme.colors.yellow : theme.colors.gray};
    }

    &:hover {
        &::before {
            background-color: ${({ theme }: SubcategoryContainerProps) => theme.colors.yellow};
        }
    }
`;

const SubcategoryHeading = styled.h3<ThemedProps>`
    display: inline-block;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.normal};
    margin: 0;
`;

interface Props {
    selected: boolean;
    subcategoryId: string;
    categoryId: string;
    name: string;
    prepareSubcategoryUrl: (subcategoryId: string, categoryId: string) => string;
    handleClose: () => void;
}

const SubcategoryItem: FunctionComponent<Props> = ({
    selected,
    subcategoryId,
    categoryId,
    name,
    prepareSubcategoryUrl,
    handleClose,
}) => {
    const subcategoryUrl = prepareSubcategoryUrl(subcategoryId, categoryId);

    return (
        <SubcategoryLink to={subcategoryUrl} onClick={handleClose} selected={selected}>
            <SubcategoryHeading>{name}</SubcategoryHeading>
        </SubcategoryLink>
    );
};

export default SubcategoryItem;
