import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import Button from './button';

interface ClosableProps extends ThemedProps {
    isOpen: boolean;
}

const AsideMenuContent = styled.div<ClosableProps>`
    background-color: ${({ theme }: ClosableProps) => theme.colors.white};
    border: 1px solid ${({ theme }: ClosableProps) => theme.colors.gray};
    border-radius: ${({ theme }: ClosableProps) => theme.borders.radius};
    padding: 1rem 1rem;
    width: 100%;

    ${({ theme }: ClosableProps) => theme.mediaQueries.mobile} {
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(${({ isOpen }: ClosableProps) => (isOpen ? '0%' : '100%')});
        z-index: 10;
        width: 100vw;
        height: 100vh;
        transition: transform 0.3s ease-in-out;
        border: unset;
        border-radius: unset;
    }
`;

const OpeningButtonTitle = styled.h4<ThemedProps>`
    margin: 0;
`;

const OpeningButton = styled(Button).attrs({ variant: 'secondary' })<ThemedProps>`
    display: none;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        border-radius: ${(props: ThemedProps) => props.theme.borders.radiusSmall};
        padding: 0.75rem 1rem;
        box-shadow: none;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        border: ${(props: ThemedProps) => `1px solid ${props.theme.colors.gray}`};

        svg {
            fill: ${({ theme }: ThemedProps) => theme.colors.darkgray};
        }

        ${OpeningButtonTitle} {
            color: ${({ theme }: ThemedProps) => theme.colors.darkgray};
        }
    }
`;

const AsideMenuContainer = styled.div`
    position: relative;
    display: flex;
`;

const ClosingButton = styled(Button)<ThemedProps>`
    display: none;
    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        display: block;
        min-height: 2.5em;
        margin-top: 2em;
    }
`;

interface Props {
    openingButtonTitle: string;
    closingButtonTitle: string;
    isOpen: boolean;
    handleSetIsOpen: () => void;
    children: ReactElement;
    openingButtonIcon: ReactElement;
}

const AsideMenu: FunctionComponent<Props> = ({
    openingButtonTitle,
    closingButtonTitle,
    isOpen,
    handleSetIsOpen,
    children,
    openingButtonIcon,
}) => {
    return (
        <AsideMenuContainer>
            <OpeningButton onClick={handleSetIsOpen}>
                <OpeningButtonTitle>{openingButtonTitle}</OpeningButtonTitle>
                {openingButtonIcon && openingButtonIcon}
            </OpeningButton>
            <AsideMenuContent isOpen={isOpen}>
                {children}
                <ClosingButton onClick={handleSetIsOpen}>{closingButtonTitle}</ClosingButton>
            </AsideMenuContent>
        </AsideMenuContainer>
    );
};

export default AsideMenu;
