import React, { FunctionComponent } from 'react';
import Img, { FluidObject } from 'gatsby-image';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';

const StateContainer = styled.div`
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StateImageContainer = styled.div`
    min-width: 250px;
    width: 100%;
    max-width: 300px;
`;

const StateMessageTitle = styled.h2<ThemedProps>`
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.large};
`;

const StateMessage = styled.p<ThemedProps>`
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.small};
    color: ${({ theme }: ThemedProps) => theme.colors.darkgray};
    max-width: 600px;
`;

interface Props {
    messageTitle: string;
    message?: string;
    imageFluid: FluidObject | FluidObject[];
    imageAlt: string;
}

const State: FunctionComponent<Props> = ({ messageTitle, message, imageFluid, imageAlt }) => {
    return (
        <StateContainer>
            <StateImageContainer>
                <Img alt={imageAlt} fluid={imageFluid} />
            </StateImageContainer>
            <StateMessageTitle>{messageTitle}</StateMessageTitle>
            {message && <StateMessage>{message}</StateMessage>}
        </StateContainer>
    );
};

export default State;
