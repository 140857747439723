import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the date-timeformat outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representationof dates and times using the Gregorian calendar. */
    DateTime: any;
    /** Slate-compatible RichText AST */
    RichTextAST: any;
    /** Raw JSON value */
    Json: any;
    /** The Long scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
    Long: any;
    RGBAHue: any;
    RGBATransparency: any;
    /** A date string, such as 2007-12-03 (YYYY-MM-DD), compliant with ISO 8601 standard for representation of dates using the Gregorian calendar. */
    Date: any;
    Hex: any;
};

export enum _FilterKind {
    Search = 'search',
    And = 'AND',
    Or = 'OR',
    Not = 'NOT',
    Eq = 'eq',
    EqNot = 'eq_not',
    In = 'in',
    NotIn = 'not_in',
    Lt = 'lt',
    Lte = 'lte',
    Gt = 'gt',
    Gte = 'gte',
    Contains = 'contains',
    NotContains = 'not_contains',
    StartsWith = 'starts_with',
    NotStartsWith = 'not_starts_with',
    EndsWith = 'ends_with',
    NotEndsWith = 'not_ends_with',
    ContainsAll = 'contains_all',
    ContainsSome = 'contains_some',
    ContainsNone = 'contains_none',
    RelationalSingle = 'relational_single',
    RelationalEvery = 'relational_every',
    RelationalSome = 'relational_some',
    RelationalNone = 'relational_none',
}

export enum _MutationInputFieldKind {
    Scalar = 'scalar',
    RichText = 'richText',
    Enum = 'enum',
    Relation = 'relation',
    Union = 'union',
    Virtual = 'virtual',
}

export enum _MutationKind {
    Create = 'create',
    Publish = 'publish',
    Unpublish = 'unpublish',
    Update = 'update',
    Upsert = 'upsert',
    Delete = 'delete',
    UpdateMany = 'updateMany',
    PublishMany = 'publishMany',
    UnpublishMany = 'unpublishMany',
    DeleteMany = 'deleteMany',
}

export enum _OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export enum _RelationInputCardinality {
    One = 'one',
    Many = 'many',
}

export enum _RelationInputKind {
    Create = 'create',
    Update = 'update',
}

export enum _RelationKind {
    Regular = 'regular',
    Union = 'union',
}

export enum _SystemDateTimeFieldVariation {
    Base = 'base',
    Localization = 'localization',
    Combined = 'combined',
}

export type Advertise = Node & {
    __typename?: 'Advertise';
    /** System stage field */
    stage: Stage;
    /** Get the document in other stages */
    documentInStages: Array<Advertise>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    url?: Maybe<Scalars['String']>;
    image: Asset;
    /** List of Advertise versions */
    history: Array<Version>;
};

export type AdvertiseDocumentInStagesArgs = {
    stages?: Array<Stage>;
    includeCurrent?: Scalars['Boolean'];
    inheritLocale?: Scalars['Boolean'];
};

export type AdvertiseHistoryArgs = {
    limit?: Scalars['Int'];
    skip?: Scalars['Int'];
    stageOverride?: Maybe<Stage>;
};

export type AdvertiseConnectInput = {
    /** Document to connect */
    where: AdvertiseWhereUniqueInput;
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type AdvertiseConnection = {
    __typename?: 'AdvertiseConnection';
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** A list of edges. */
    edges: Array<AdvertiseEdge>;
    aggregate: Aggregate;
};

export type AdvertiseCreateInput = {
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    url?: Maybe<Scalars['String']>;
    image: AssetCreateOneInlineInput;
};

export type AdvertiseCreateManyInlineInput = {
    /** Create and connect multiple existing Advertise documents */
    create?: Maybe<Array<AdvertiseCreateInput>>;
    /** Connect multiple existing Advertise documents */
    connect?: Maybe<Array<AdvertiseWhereUniqueInput>>;
};

export type AdvertiseCreateOneInlineInput = {
    /** Create and connect one Advertise document */
    create?: Maybe<AdvertiseCreateInput>;
    /** Connect one existing Advertise document */
    connect?: Maybe<AdvertiseWhereUniqueInput>;
};

/** An edge in a connection. */
export type AdvertiseEdge = {
    __typename?: 'AdvertiseEdge';
    /** The item at the end of the edge. */
    node: Advertise;
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
};

/** Identifies documents */
export type AdvertiseManyWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<AdvertiseWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<AdvertiseWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<AdvertiseWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    url?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    url_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    url_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    url_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    url_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    url_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    url_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    url_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    url_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    url_not_ends_with?: Maybe<Scalars['String']>;
    image?: Maybe<AssetWhereInput>;
};

export enum AdvertiseOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    UrlAsc = 'url_ASC',
    UrlDesc = 'url_DESC',
}

export type AdvertiseUpdateInput = {
    url?: Maybe<Scalars['String']>;
    image?: Maybe<AssetUpdateOneInlineInput>;
};

export type AdvertiseUpdateManyInlineInput = {
    /** Create and connect multiple Advertise documents */
    create?: Maybe<Array<AdvertiseCreateInput>>;
    /** Connect multiple existing Advertise documents */
    connect?: Maybe<Array<AdvertiseConnectInput>>;
    /** Override currently-connected documents with multiple existing Advertise documents */
    set?: Maybe<Array<AdvertiseWhereUniqueInput>>;
    /** Update multiple Advertise documents */
    update?: Maybe<Array<AdvertiseUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Advertise documents */
    upsert?: Maybe<Array<AdvertiseUpsertWithNestedWhereUniqueInput>>;
    /** Disconnect multiple Advertise documents */
    disconnect?: Maybe<Array<AdvertiseWhereUniqueInput>>;
    /** Delete multiple Advertise documents */
    delete?: Maybe<Array<AdvertiseWhereUniqueInput>>;
};

export type AdvertiseUpdateManyInput = {
    /** No fields in updateMany data input */
    _?: Maybe<Scalars['String']>;
};

export type AdvertiseUpdateManyWithNestedWhereInput = {
    /** Document search */
    where: AdvertiseWhereInput;
    /** Update many input */
    data: AdvertiseUpdateManyInput;
};

export type AdvertiseUpdateOneInlineInput = {
    /** Create and connect one Advertise document */
    create?: Maybe<AdvertiseCreateInput>;
    /** Update single Advertise document */
    update?: Maybe<AdvertiseUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Advertise document */
    upsert?: Maybe<AdvertiseUpsertWithNestedWhereUniqueInput>;
    /** Connect existing Advertise document */
    connect?: Maybe<AdvertiseWhereUniqueInput>;
    /** Disconnect currently connected Advertise document */
    disconnect?: Maybe<Scalars['Boolean']>;
    /** Delete currently connected Advertise document */
    delete?: Maybe<Scalars['Boolean']>;
};

export type AdvertiseUpdateWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: AdvertiseWhereUniqueInput;
    /** Document to update */
    data: AdvertiseUpdateInput;
};

export type AdvertiseUpsertInput = {
    /** Create document if it didn't exist */
    create: AdvertiseCreateInput;
    /** Update document if it exists */
    update: AdvertiseUpdateInput;
};

export type AdvertiseUpsertWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: AdvertiseWhereUniqueInput;
    /** Upsert data */
    data: AdvertiseUpsertInput;
};

/** Identifies documents */
export type AdvertiseWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<AdvertiseWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<AdvertiseWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<AdvertiseWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    url?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    url_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    url_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    url_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    url_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    url_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    url_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    url_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    url_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    url_not_ends_with?: Maybe<Scalars['String']>;
    image?: Maybe<AssetWhereInput>;
};

/** References Advertise record uniquely */
export type AdvertiseWhereUniqueInput = {
    id?: Maybe<Scalars['ID']>;
    url?: Maybe<Scalars['String']>;
};

export type Aggregate = {
    __typename?: 'Aggregate';
    count: Scalars['Int'];
};

/** Asset system model */
export type Asset = Node & {
    __typename?: 'Asset';
    /** System stage field */
    stage: Stage;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Asset>;
    /** Get the document in other stages */
    documentInStages: Array<Asset>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** The file handle */
    handle: Scalars['String'];
    /** The file name */
    fileName: Scalars['String'];
    /** The height of the file */
    height?: Maybe<Scalars['Float']>;
    /** The file width */
    width?: Maybe<Scalars['Float']>;
    /** The file size */
    size?: Maybe<Scalars['Float']>;
    /** The mime type of the file */
    mimeType?: Maybe<Scalars['String']>;
    postMainImage: Array<Post>;
    postImages: Array<Post>;
    productMainImage: Array<Product>;
    productImages: Array<Product>;
    imageAdvertise: Array<Advertise>;
    /** List of Asset versions */
    history: Array<Version>;
    /** Get the url for the asset with provided transformations applied. */
    url: Scalars['String'];
};

/** Asset system model */
export type AssetLocalizationsArgs = {
    locales?: Array<Locale>;
    includeCurrent?: Scalars['Boolean'];
};

/** Asset system model */
export type AssetDocumentInStagesArgs = {
    stages?: Array<Stage>;
    includeCurrent?: Scalars['Boolean'];
    inheritLocale?: Scalars['Boolean'];
};

/** Asset system model */
export type AssetCreatedAtArgs = {
    variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetUpdatedAtArgs = {
    variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPublishedAtArgs = {
    variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPostMainImageArgs = {
    where?: Maybe<PostWhereInput>;
    orderBy?: Maybe<PostOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Asset system model */
export type AssetPostImagesArgs = {
    where?: Maybe<PostWhereInput>;
    orderBy?: Maybe<PostOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Asset system model */
export type AssetProductMainImageArgs = {
    where?: Maybe<ProductWhereInput>;
    orderBy?: Maybe<ProductOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Asset system model */
export type AssetProductImagesArgs = {
    where?: Maybe<ProductWhereInput>;
    orderBy?: Maybe<ProductOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Asset system model */
export type AssetImageAdvertiseArgs = {
    where?: Maybe<AdvertiseWhereInput>;
    orderBy?: Maybe<AdvertiseOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Asset system model */
export type AssetHistoryArgs = {
    limit?: Scalars['Int'];
    skip?: Scalars['Int'];
    stageOverride?: Maybe<Stage>;
};

/** Asset system model */
export type AssetUrlArgs = {
    transformation?: Maybe<AssetTransformationInput>;
};

export type AssetConnectInput = {
    /** Document to connect */
    where: AssetWhereUniqueInput;
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type AssetConnection = {
    __typename?: 'AssetConnection';
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** A list of edges. */
    edges: Array<AssetEdge>;
    aggregate: Aggregate;
};

export type AssetCreateInput = {
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    handle: Scalars['String'];
    fileName: Scalars['String'];
    height?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Float']>;
    mimeType?: Maybe<Scalars['String']>;
    postMainImage?: Maybe<PostCreateManyInlineInput>;
    postImages?: Maybe<PostCreateManyInlineInput>;
    productMainImage?: Maybe<ProductCreateManyInlineInput>;
    productImages?: Maybe<ProductCreateManyInlineInput>;
    imageAdvertise?: Maybe<AdvertiseCreateManyInlineInput>;
    /** Inline mutations for managing document localizations excluding the default locale */
    localizations?: Maybe<AssetCreateLocalizationsInput>;
};

export type AssetCreateLocalizationDataInput = {
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    handle: Scalars['String'];
    fileName: Scalars['String'];
    height?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Float']>;
    mimeType?: Maybe<Scalars['String']>;
};

export type AssetCreateLocalizationInput = {
    /** Localization input */
    data: AssetCreateLocalizationDataInput;
    locale: Locale;
};

export type AssetCreateLocalizationsInput = {
    /** Create localizations for the newly-created document */
    create?: Maybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
    /** Create and connect multiple existing Asset documents */
    create?: Maybe<Array<AssetCreateInput>>;
    /** Connect multiple existing Asset documents */
    connect?: Maybe<Array<AssetWhereUniqueInput>>;
};

export type AssetCreateOneInlineInput = {
    /** Create and connect one Asset document */
    create?: Maybe<AssetCreateInput>;
    /** Connect one existing Asset document */
    connect?: Maybe<AssetWhereUniqueInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
    __typename?: 'AssetEdge';
    /** The item at the end of the edge. */
    node: Asset;
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
};

/** Identifies documents */
export type AssetManyWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<AssetWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<AssetWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<AssetWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    postMainImage_every?: Maybe<PostWhereInput>;
    postMainImage_some?: Maybe<PostWhereInput>;
    postMainImage_none?: Maybe<PostWhereInput>;
    postImages_every?: Maybe<PostWhereInput>;
    postImages_some?: Maybe<PostWhereInput>;
    postImages_none?: Maybe<PostWhereInput>;
    productMainImage_every?: Maybe<ProductWhereInput>;
    productMainImage_some?: Maybe<ProductWhereInput>;
    productMainImage_none?: Maybe<ProductWhereInput>;
    productImages_every?: Maybe<ProductWhereInput>;
    productImages_some?: Maybe<ProductWhereInput>;
    productImages_none?: Maybe<ProductWhereInput>;
    imageAdvertise_every?: Maybe<AdvertiseWhereInput>;
    imageAdvertise_some?: Maybe<AdvertiseWhereInput>;
    imageAdvertise_none?: Maybe<AdvertiseWhereInput>;
};

export enum AssetOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    HandleAsc = 'handle_ASC',
    HandleDesc = 'handle_DESC',
    FileNameAsc = 'fileName_ASC',
    FileNameDesc = 'fileName_DESC',
    HeightAsc = 'height_ASC',
    HeightDesc = 'height_DESC',
    WidthAsc = 'width_ASC',
    WidthDesc = 'width_DESC',
    SizeAsc = 'size_ASC',
    SizeDesc = 'size_DESC',
    MimeTypeAsc = 'mimeType_ASC',
    MimeTypeDesc = 'mimeType_DESC',
}

/** Transformations for Assets */
export type AssetTransformationInput = {
    image?: Maybe<ImageTransformationInput>;
    document?: Maybe<DocumentTransformationInput>;
    /** Pass true if you want to validate the passed transformation parameters */
    validateOptions?: Maybe<Scalars['Boolean']>;
};

export type AssetUpdateInput = {
    handle?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    height?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Float']>;
    mimeType?: Maybe<Scalars['String']>;
    postMainImage?: Maybe<PostUpdateManyInlineInput>;
    postImages?: Maybe<PostUpdateManyInlineInput>;
    productMainImage?: Maybe<ProductUpdateManyInlineInput>;
    productImages?: Maybe<ProductUpdateManyInlineInput>;
    imageAdvertise?: Maybe<AdvertiseUpdateManyInlineInput>;
    /** Manage document localizations */
    localizations?: Maybe<AssetUpdateLocalizationsInput>;
};

export type AssetUpdateLocalizationDataInput = {
    handle?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    height?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Float']>;
    mimeType?: Maybe<Scalars['String']>;
};

export type AssetUpdateLocalizationInput = {
    data: AssetUpdateLocalizationDataInput;
    locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
    /** Localizations to create */
    create?: Maybe<Array<AssetCreateLocalizationInput>>;
    /** Localizations to update */
    update?: Maybe<Array<AssetUpdateLocalizationInput>>;
    upsert?: Maybe<Array<AssetUpsertLocalizationInput>>;
    /** Localizations to delete */
    delete?: Maybe<Array<Locale>>;
};

export type AssetUpdateManyInlineInput = {
    /** Create and connect multiple Asset documents */
    create?: Maybe<Array<AssetCreateInput>>;
    /** Connect multiple existing Asset documents */
    connect?: Maybe<Array<AssetConnectInput>>;
    /** Override currently-connected documents with multiple existing Asset documents */
    set?: Maybe<Array<AssetWhereUniqueInput>>;
    /** Update multiple Asset documents */
    update?: Maybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Asset documents */
    upsert?: Maybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
    /** Disconnect multiple Asset documents */
    disconnect?: Maybe<Array<AssetWhereUniqueInput>>;
    /** Delete multiple Asset documents */
    delete?: Maybe<Array<AssetWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
    fileName?: Maybe<Scalars['String']>;
    height?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Float']>;
    mimeType?: Maybe<Scalars['String']>;
    /** Optional updates to localizations */
    localizations?: Maybe<AssetUpdateManyLocalizationsInput>;
};

export type AssetUpdateManyLocalizationDataInput = {
    fileName?: Maybe<Scalars['String']>;
    height?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Float']>;
    mimeType?: Maybe<Scalars['String']>;
};

export type AssetUpdateManyLocalizationInput = {
    data: AssetUpdateManyLocalizationDataInput;
    locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
    /** Localizations to update */
    update?: Maybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
    /** Document search */
    where: AssetWhereInput;
    /** Update many input */
    data: AssetUpdateManyInput;
};

export type AssetUpdateOneInlineInput = {
    /** Create and connect one Asset document */
    create?: Maybe<AssetCreateInput>;
    /** Update single Asset document */
    update?: Maybe<AssetUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Asset document */
    upsert?: Maybe<AssetUpsertWithNestedWhereUniqueInput>;
    /** Connect existing Asset document */
    connect?: Maybe<AssetWhereUniqueInput>;
    /** Disconnect currently connected Asset document */
    disconnect?: Maybe<Scalars['Boolean']>;
    /** Delete currently connected Asset document */
    delete?: Maybe<Scalars['Boolean']>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: AssetWhereUniqueInput;
    /** Document to update */
    data: AssetUpdateInput;
};

export type AssetUpsertInput = {
    /** Create document if it didn't exist */
    create: AssetCreateInput;
    /** Update document if it exists */
    update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
    update: AssetUpdateLocalizationDataInput;
    create: AssetCreateLocalizationDataInput;
    locale: Locale;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: AssetWhereUniqueInput;
    /** Upsert data */
    data: AssetUpsertInput;
};

/** Identifies documents */
export type AssetWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<AssetWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<AssetWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<AssetWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    handle?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    handle_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    handle_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    handle_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    handle_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    handle_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    handle_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    handle_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    handle_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    handle_not_ends_with?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    fileName_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    fileName_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    fileName_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    fileName_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    fileName_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    fileName_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    fileName_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    fileName_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    fileName_not_ends_with?: Maybe<Scalars['String']>;
    height?: Maybe<Scalars['Float']>;
    /** All values that are not equal to given value. */
    height_not?: Maybe<Scalars['Float']>;
    /** All values that are contained in given list. */
    height_in?: Maybe<Array<Scalars['Float']>>;
    /** All values that are not contained in given list. */
    height_not_in?: Maybe<Array<Scalars['Float']>>;
    /** All values less than the given value. */
    height_lt?: Maybe<Scalars['Float']>;
    /** All values less than or equal the given value. */
    height_lte?: Maybe<Scalars['Float']>;
    /** All values greater than the given value. */
    height_gt?: Maybe<Scalars['Float']>;
    /** All values greater than or equal the given value. */
    height_gte?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    /** All values that are not equal to given value. */
    width_not?: Maybe<Scalars['Float']>;
    /** All values that are contained in given list. */
    width_in?: Maybe<Array<Scalars['Float']>>;
    /** All values that are not contained in given list. */
    width_not_in?: Maybe<Array<Scalars['Float']>>;
    /** All values less than the given value. */
    width_lt?: Maybe<Scalars['Float']>;
    /** All values less than or equal the given value. */
    width_lte?: Maybe<Scalars['Float']>;
    /** All values greater than the given value. */
    width_gt?: Maybe<Scalars['Float']>;
    /** All values greater than or equal the given value. */
    width_gte?: Maybe<Scalars['Float']>;
    size?: Maybe<Scalars['Float']>;
    /** All values that are not equal to given value. */
    size_not?: Maybe<Scalars['Float']>;
    /** All values that are contained in given list. */
    size_in?: Maybe<Array<Scalars['Float']>>;
    /** All values that are not contained in given list. */
    size_not_in?: Maybe<Array<Scalars['Float']>>;
    /** All values less than the given value. */
    size_lt?: Maybe<Scalars['Float']>;
    /** All values less than or equal the given value. */
    size_lte?: Maybe<Scalars['Float']>;
    /** All values greater than the given value. */
    size_gt?: Maybe<Scalars['Float']>;
    /** All values greater than or equal the given value. */
    size_gte?: Maybe<Scalars['Float']>;
    mimeType?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    mimeType_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    mimeType_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    mimeType_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    mimeType_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    mimeType_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    mimeType_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    mimeType_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    mimeType_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    mimeType_not_ends_with?: Maybe<Scalars['String']>;
    postMainImage_every?: Maybe<PostWhereInput>;
    postMainImage_some?: Maybe<PostWhereInput>;
    postMainImage_none?: Maybe<PostWhereInput>;
    postImages_every?: Maybe<PostWhereInput>;
    postImages_some?: Maybe<PostWhereInput>;
    postImages_none?: Maybe<PostWhereInput>;
    productMainImage_every?: Maybe<ProductWhereInput>;
    productMainImage_some?: Maybe<ProductWhereInput>;
    productMainImage_none?: Maybe<ProductWhereInput>;
    productImages_every?: Maybe<ProductWhereInput>;
    productImages_some?: Maybe<ProductWhereInput>;
    productImages_none?: Maybe<ProductWhereInput>;
    imageAdvertise_every?: Maybe<AdvertiseWhereInput>;
    imageAdvertise_some?: Maybe<AdvertiseWhereInput>;
    imageAdvertise_none?: Maybe<AdvertiseWhereInput>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
    id?: Maybe<Scalars['ID']>;
};

export type BatchPayload = {
    __typename?: 'BatchPayload';
    /** The number of nodes that have been affected by the Batch operation. */
    count: Scalars['Long'];
};

/** Category of an offered product */
export type Category = Node & {
    __typename?: 'Category';
    /** System stage field */
    stage: Stage;
    /** Get the document in other stages */
    documentInStages: Array<Category>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** Name of product category */
    name: Scalars['String'];
    /** Subcategories of an offered product */
    subcategories: Array<Subcategory>;
    /** List of Category versions */
    history: Array<Version>;
};

/** Category of an offered product */
export type CategoryDocumentInStagesArgs = {
    stages?: Array<Stage>;
    includeCurrent?: Scalars['Boolean'];
    inheritLocale?: Scalars['Boolean'];
};

/** Category of an offered product */
export type CategorySubcategoriesArgs = {
    where?: Maybe<SubcategoryWhereInput>;
    orderBy?: Maybe<SubcategoryOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Category of an offered product */
export type CategoryHistoryArgs = {
    limit?: Scalars['Int'];
    skip?: Scalars['Int'];
    stageOverride?: Maybe<Stage>;
};

export type CategoryConnectInput = {
    /** Document to connect */
    where: CategoryWhereUniqueInput;
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type CategoryConnection = {
    __typename?: 'CategoryConnection';
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** A list of edges. */
    edges: Array<CategoryEdge>;
    aggregate: Aggregate;
};

export type CategoryCreateInput = {
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
    subcategories?: Maybe<SubcategoryCreateManyInlineInput>;
};

export type CategoryCreateManyInlineInput = {
    /** Create and connect multiple existing Category documents */
    create?: Maybe<Array<CategoryCreateInput>>;
    /** Connect multiple existing Category documents */
    connect?: Maybe<Array<CategoryWhereUniqueInput>>;
};

export type CategoryCreateOneInlineInput = {
    /** Create and connect one Category document */
    create?: Maybe<CategoryCreateInput>;
    /** Connect one existing Category document */
    connect?: Maybe<CategoryWhereUniqueInput>;
};

/** An edge in a connection. */
export type CategoryEdge = {
    __typename?: 'CategoryEdge';
    /** The item at the end of the edge. */
    node: Category;
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
};

/** Identifies documents */
export type CategoryManyWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<CategoryWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<CategoryWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<CategoryWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    name_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    name_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    name_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    name_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    name_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    name_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    name_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    name_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    name_not_ends_with?: Maybe<Scalars['String']>;
    subcategories_every?: Maybe<SubcategoryWhereInput>;
    subcategories_some?: Maybe<SubcategoryWhereInput>;
    subcategories_none?: Maybe<SubcategoryWhereInput>;
};

export enum CategoryOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
}

export type CategoryUpdateInput = {
    name?: Maybe<Scalars['String']>;
    subcategories?: Maybe<SubcategoryUpdateManyInlineInput>;
};

export type CategoryUpdateManyInlineInput = {
    /** Create and connect multiple Category documents */
    create?: Maybe<Array<CategoryCreateInput>>;
    /** Connect multiple existing Category documents */
    connect?: Maybe<Array<CategoryConnectInput>>;
    /** Override currently-connected documents with multiple existing Category documents */
    set?: Maybe<Array<CategoryWhereUniqueInput>>;
    /** Update multiple Category documents */
    update?: Maybe<Array<CategoryUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Category documents */
    upsert?: Maybe<Array<CategoryUpsertWithNestedWhereUniqueInput>>;
    /** Disconnect multiple Category documents */
    disconnect?: Maybe<Array<CategoryWhereUniqueInput>>;
    /** Delete multiple Category documents */
    delete?: Maybe<Array<CategoryWhereUniqueInput>>;
};

export type CategoryUpdateManyInput = {
    /** No fields in updateMany data input */
    _?: Maybe<Scalars['String']>;
};

export type CategoryUpdateManyWithNestedWhereInput = {
    /** Document search */
    where: CategoryWhereInput;
    /** Update many input */
    data: CategoryUpdateManyInput;
};

export type CategoryUpdateOneInlineInput = {
    /** Create and connect one Category document */
    create?: Maybe<CategoryCreateInput>;
    /** Update single Category document */
    update?: Maybe<CategoryUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Category document */
    upsert?: Maybe<CategoryUpsertWithNestedWhereUniqueInput>;
    /** Connect existing Category document */
    connect?: Maybe<CategoryWhereUniqueInput>;
    /** Disconnect currently connected Category document */
    disconnect?: Maybe<Scalars['Boolean']>;
    /** Delete currently connected Category document */
    delete?: Maybe<Scalars['Boolean']>;
};

export type CategoryUpdateWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: CategoryWhereUniqueInput;
    /** Document to update */
    data: CategoryUpdateInput;
};

export type CategoryUpsertInput = {
    /** Create document if it didn't exist */
    create: CategoryCreateInput;
    /** Update document if it exists */
    update: CategoryUpdateInput;
};

export type CategoryUpsertWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: CategoryWhereUniqueInput;
    /** Upsert data */
    data: CategoryUpsertInput;
};

/** Identifies documents */
export type CategoryWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<CategoryWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<CategoryWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<CategoryWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    name_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    name_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    name_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    name_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    name_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    name_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    name_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    name_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    name_not_ends_with?: Maybe<Scalars['String']>;
    subcategories_every?: Maybe<SubcategoryWhereInput>;
    subcategories_some?: Maybe<SubcategoryWhereInput>;
    subcategories_none?: Maybe<SubcategoryWhereInput>;
};

/** References Category record uniquely */
export type CategoryWhereUniqueInput = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
    __typename?: 'Color';
    hex: Scalars['Hex'];
    rgba: Rgba;
    css: Scalars['String'];
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
    hex?: Maybe<Scalars['Hex']>;
    rgba?: Maybe<RgbaInput>;
};

export type ConnectPositionInput = {
    /** Connect document after specified document */
    after?: Maybe<Scalars['ID']>;
    /** Connect document before specified document */
    before?: Maybe<Scalars['ID']>;
    /** Connect document at first position */
    start?: Maybe<Scalars['Boolean']>;
    /** Connect document at last position */
    end?: Maybe<Scalars['Boolean']>;
};

export enum DocumentFileTypes {
    Jpg = 'jpg',
    Odp = 'odp',
    Ods = 'ods',
    Odt = 'odt',
    Png = 'png',
    Svg = 'svg',
    Txt = 'txt',
    Webp = 'webp',
    Docx = 'docx',
    Pdf = 'pdf',
    Html = 'html',
    Doc = 'doc',
    Xlsx = 'xlsx',
    Xls = 'xls',
    Pptx = 'pptx',
    Ppt = 'ppt',
}

export type DocumentOutputInput = {
    /**
     * Transforms a document into a desired file type.
     * See this matrix for format support:
     *
     * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
     * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
     * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
     * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
     * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
     * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
     * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
     * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
     * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
     * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
     * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
     * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
     * SVG:	jpg, odp, ods, odt, pdf, png, and webp
     * HTML:	jpg, odt, pdf, svg, txt, and webp
     * TXT:	jpg, html, odt, pdf, svg, and webp
     */
    format?: Maybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
    /** Changes the output for the file. */
    output?: Maybe<DocumentOutputInput>;
};

export type DocumentVersion = {
    __typename?: 'DocumentVersion';
    id: Scalars['ID'];
    stage: Stage;
    revision: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    data?: Maybe<Scalars['Json']>;
};

export enum ImageFit {
    /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
    Clip = 'clip',
    /**
     * Resizes the image to fit the specified parameters exactly by removing any
     * parts of the image that don't fit within the boundaries.
     */
    Crop = 'crop',
    /**
     * Resizes the image to fit the specified parameters exactly by scaling the image
     * to the desired size. The aspect ratio of the image is not respected and the
     * image can be distorted using this method.
     */
    Scale = 'scale',
    /**
     * Resizes the image to fit within the parameters, but as opposed to 'fit:clip'
     * will not scale the image if the image is smaller than the output size.
     */
    Max = 'max',
}

export type ImageResizeInput = {
    /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
    width?: Maybe<Scalars['Int']>;
    /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
    height?: Maybe<Scalars['Int']>;
    /** The default value for the fit parameter is fit:clip. */
    fit?: Maybe<ImageFit>;
};

/** Transformations for Images */
export type ImageTransformationInput = {
    /** Resizes the image */
    resize?: Maybe<ImageResizeInput>;
};

/** Locale system enumeration */
export enum Locale {
    /** System locale */
    En = 'en',
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
    __typename?: 'Location';
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
    distance: Scalars['Float'];
};

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
    from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Create one advertise */
    createAdvertise?: Maybe<Advertise>;
    /** Update one advertise */
    updateAdvertise?: Maybe<Advertise>;
    /** Delete one advertise from _all_ existing stages. Returns deleted document. */
    deleteAdvertise?: Maybe<Advertise>;
    /** Upsert one advertise */
    upsertAdvertise?: Maybe<Advertise>;
    /** Publish one advertise */
    publishAdvertise?: Maybe<Advertise>;
    /**
     * Unpublish one advertise from selected stages. Unpublish either the complete
     * document with its relations, localizations and base data or specific
     * localizations only.
     */
    unpublishAdvertise?: Maybe<Advertise>;
    /** Update many Advertise documents */
    updateManyAdvertisesConnection: AdvertiseConnection;
    /** Delete many Advertise documents, return deleted documents */
    deleteManyAdvertisesConnection: AdvertiseConnection;
    /** Publish many Advertise documents */
    publishManyAdvertisesConnection: AdvertiseConnection;
    /** Find many Advertise documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyAdvertisesConnection: AdvertiseConnection;
    /**
     * Update many advertises
     * @deprecated Please use the new paginated many mutation (updateManyAdvertisesConnection)
     */
    updateManyAdvertises: BatchPayload;
    /**
     * Delete many Advertise documents
     * @deprecated Please use the new paginated many mutation (deleteManyAdvertisesConnection)
     */
    deleteManyAdvertises: BatchPayload;
    /**
     * Publish many Advertise documents
     * @deprecated Please use the new paginated many mutation (publishManyAdvertisesConnection)
     */
    publishManyAdvertises: BatchPayload;
    /**
     * Unpublish many Advertise documents
     * @deprecated Please use the new paginated many mutation (unpublishManyAdvertisesConnection)
     */
    unpublishManyAdvertises: BatchPayload;
    /**
     * Create one asset
     * @deprecated Asset mutations will be overhauled soon
     */
    createAsset?: Maybe<Asset>;
    /** Update one asset */
    updateAsset?: Maybe<Asset>;
    /** Delete one asset from _all_ existing stages. Returns deleted document. */
    deleteAsset?: Maybe<Asset>;
    /** Upsert one asset */
    upsertAsset?: Maybe<Asset>;
    /** Publish one asset */
    publishAsset?: Maybe<Asset>;
    /**
     * Unpublish one asset from selected stages. Unpublish either the complete
     * document with its relations, localizations and base data or specific
     * localizations only.
     */
    unpublishAsset?: Maybe<Asset>;
    /** Update many Asset documents */
    updateManyAssetsConnection: AssetConnection;
    /** Delete many Asset documents, return deleted documents */
    deleteManyAssetsConnection: AssetConnection;
    /** Publish many Asset documents */
    publishManyAssetsConnection: AssetConnection;
    /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyAssetsConnection: AssetConnection;
    /**
     * Update many assets
     * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
     */
    updateManyAssets: BatchPayload;
    /**
     * Delete many Asset documents
     * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
     */
    deleteManyAssets: BatchPayload;
    /**
     * Publish many Asset documents
     * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
     */
    publishManyAssets: BatchPayload;
    /**
     * Unpublish many Asset documents
     * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
     */
    unpublishManyAssets: BatchPayload;
    /** Create one category */
    createCategory?: Maybe<Category>;
    /** Update one category */
    updateCategory?: Maybe<Category>;
    /** Delete one category from _all_ existing stages. Returns deleted document. */
    deleteCategory?: Maybe<Category>;
    /** Upsert one category */
    upsertCategory?: Maybe<Category>;
    /** Publish one category */
    publishCategory?: Maybe<Category>;
    /**
     * Unpublish one category from selected stages. Unpublish either the complete
     * document with its relations, localizations and base data or specific
     * localizations only.
     */
    unpublishCategory?: Maybe<Category>;
    /** Update many Category documents */
    updateManyCategoriesConnection: CategoryConnection;
    /** Delete many Category documents, return deleted documents */
    deleteManyCategoriesConnection: CategoryConnection;
    /** Publish many Category documents */
    publishManyCategoriesConnection: CategoryConnection;
    /** Find many Category documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyCategoriesConnection: CategoryConnection;
    /**
     * Update many categories
     * @deprecated Please use the new paginated many mutation (updateManyCategoriesConnection)
     */
    updateManyCategories: BatchPayload;
    /**
     * Delete many Category documents
     * @deprecated Please use the new paginated many mutation (deleteManyCategoriesConnection)
     */
    deleteManyCategories: BatchPayload;
    /**
     * Publish many Category documents
     * @deprecated Please use the new paginated many mutation (publishManyCategoriesConnection)
     */
    publishManyCategories: BatchPayload;
    /**
     * Unpublish many Category documents
     * @deprecated Please use the new paginated many mutation (unpublishManyCategoriesConnection)
     */
    unpublishManyCategories: BatchPayload;
    /** Create one post */
    createPost?: Maybe<Post>;
    /** Update one post */
    updatePost?: Maybe<Post>;
    /** Delete one post from _all_ existing stages. Returns deleted document. */
    deletePost?: Maybe<Post>;
    /** Upsert one post */
    upsertPost?: Maybe<Post>;
    /** Publish one post */
    publishPost?: Maybe<Post>;
    /**
     * Unpublish one post from selected stages. Unpublish either the complete
     * document with its relations, localizations and base data or specific
     * localizations only.
     */
    unpublishPost?: Maybe<Post>;
    /** Update many Post documents */
    updateManyPostsConnection: PostConnection;
    /** Delete many Post documents, return deleted documents */
    deleteManyPostsConnection: PostConnection;
    /** Publish many Post documents */
    publishManyPostsConnection: PostConnection;
    /** Find many Post documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyPostsConnection: PostConnection;
    /**
     * Update many posts
     * @deprecated Please use the new paginated many mutation (updateManyPostsConnection)
     */
    updateManyPosts: BatchPayload;
    /**
     * Delete many Post documents
     * @deprecated Please use the new paginated many mutation (deleteManyPostsConnection)
     */
    deleteManyPosts: BatchPayload;
    /**
     * Publish many Post documents
     * @deprecated Please use the new paginated many mutation (publishManyPostsConnection)
     */
    publishManyPosts: BatchPayload;
    /**
     * Unpublish many Post documents
     * @deprecated Please use the new paginated many mutation (unpublishManyPostsConnection)
     */
    unpublishManyPosts: BatchPayload;
    /** Create one product */
    createProduct?: Maybe<Product>;
    /** Update one product */
    updateProduct?: Maybe<Product>;
    /** Delete one product from _all_ existing stages. Returns deleted document. */
    deleteProduct?: Maybe<Product>;
    /** Upsert one product */
    upsertProduct?: Maybe<Product>;
    /** Publish one product */
    publishProduct?: Maybe<Product>;
    /**
     * Unpublish one product from selected stages. Unpublish either the complete
     * document with its relations, localizations and base data or specific
     * localizations only.
     */
    unpublishProduct?: Maybe<Product>;
    /** Update many Product documents */
    updateManyProductsConnection: ProductConnection;
    /** Delete many Product documents, return deleted documents */
    deleteManyProductsConnection: ProductConnection;
    /** Publish many Product documents */
    publishManyProductsConnection: ProductConnection;
    /** Find many Product documents that match criteria in specified stage and unpublish from target stages */
    unpublishManyProductsConnection: ProductConnection;
    /**
     * Update many products
     * @deprecated Please use the new paginated many mutation (updateManyProductsConnection)
     */
    updateManyProducts: BatchPayload;
    /**
     * Delete many Product documents
     * @deprecated Please use the new paginated many mutation (deleteManyProductsConnection)
     */
    deleteManyProducts: BatchPayload;
    /**
     * Publish many Product documents
     * @deprecated Please use the new paginated many mutation (publishManyProductsConnection)
     */
    publishManyProducts: BatchPayload;
    /**
     * Unpublish many Product documents
     * @deprecated Please use the new paginated many mutation (unpublishManyProductsConnection)
     */
    unpublishManyProducts: BatchPayload;
    /** Create one subcategory */
    createSubcategory?: Maybe<Subcategory>;
    /** Update one subcategory */
    updateSubcategory?: Maybe<Subcategory>;
    /** Delete one subcategory from _all_ existing stages. Returns deleted document. */
    deleteSubcategory?: Maybe<Subcategory>;
    /** Upsert one subcategory */
    upsertSubcategory?: Maybe<Subcategory>;
    /** Publish one subcategory */
    publishSubcategory?: Maybe<Subcategory>;
    /**
     * Unpublish one subcategory from selected stages. Unpublish either the complete
     * document with its relations, localizations and base data or specific
     * localizations only.
     */
    unpublishSubcategory?: Maybe<Subcategory>;
    /** Update many Subcategory documents */
    updateManySubcategoriesConnection: SubcategoryConnection;
    /** Delete many Subcategory documents, return deleted documents */
    deleteManySubcategoriesConnection: SubcategoryConnection;
    /** Publish many Subcategory documents */
    publishManySubcategoriesConnection: SubcategoryConnection;
    /** Find many Subcategory documents that match criteria in specified stage and unpublish from target stages */
    unpublishManySubcategoriesConnection: SubcategoryConnection;
    /**
     * Update many subcategories
     * @deprecated Please use the new paginated many mutation (updateManySubcategoriesConnection)
     */
    updateManySubcategories: BatchPayload;
    /**
     * Delete many Subcategory documents
     * @deprecated Please use the new paginated many mutation (deleteManySubcategoriesConnection)
     */
    deleteManySubcategories: BatchPayload;
    /**
     * Publish many Subcategory documents
     * @deprecated Please use the new paginated many mutation (publishManySubcategoriesConnection)
     */
    publishManySubcategories: BatchPayload;
    /**
     * Unpublish many Subcategory documents
     * @deprecated Please use the new paginated many mutation (unpublishManySubcategoriesConnection)
     */
    unpublishManySubcategories: BatchPayload;
};

export type MutationCreateAdvertiseArgs = {
    data: AdvertiseCreateInput;
};

export type MutationUpdateAdvertiseArgs = {
    where: AdvertiseWhereUniqueInput;
    data: AdvertiseUpdateInput;
};

export type MutationDeleteAdvertiseArgs = {
    where: AdvertiseWhereUniqueInput;
};

export type MutationUpsertAdvertiseArgs = {
    where: AdvertiseWhereUniqueInput;
    upsert: AdvertiseUpsertInput;
};

export type MutationPublishAdvertiseArgs = {
    where: AdvertiseWhereUniqueInput;
    to?: Array<Stage>;
};

export type MutationUnpublishAdvertiseArgs = {
    where: AdvertiseWhereUniqueInput;
    from?: Array<Stage>;
};

export type MutationUpdateManyAdvertisesConnectionArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
    data: AdvertiseUpdateManyInput;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationDeleteManyAdvertisesConnectionArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationPublishManyAdvertisesConnectionArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
    from?: Maybe<Stage>;
    to?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUnpublishManyAdvertisesConnectionArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
    stage?: Maybe<Stage>;
    from?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUpdateManyAdvertisesArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
    data: AdvertiseUpdateManyInput;
};

export type MutationDeleteManyAdvertisesArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
};

export type MutationPublishManyAdvertisesArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
    to?: Array<Stage>;
};

export type MutationUnpublishManyAdvertisesArgs = {
    where?: Maybe<AdvertiseManyWhereInput>;
    from?: Array<Stage>;
};

export type MutationCreateAssetArgs = {
    data: AssetCreateInput;
};

export type MutationUpdateAssetArgs = {
    where: AssetWhereUniqueInput;
    data: AssetUpdateInput;
};

export type MutationDeleteAssetArgs = {
    where: AssetWhereUniqueInput;
};

export type MutationUpsertAssetArgs = {
    where: AssetWhereUniqueInput;
    upsert: AssetUpsertInput;
};

export type MutationPublishAssetArgs = {
    where: AssetWhereUniqueInput;
    locales?: Maybe<Array<Locale>>;
    publishBase?: Maybe<Scalars['Boolean']>;
    to?: Array<Stage>;
};

export type MutationUnpublishAssetArgs = {
    where: AssetWhereUniqueInput;
    from?: Array<Stage>;
    locales?: Maybe<Array<Locale>>;
    unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateManyAssetsConnectionArgs = {
    where?: Maybe<AssetManyWhereInput>;
    data: AssetUpdateManyInput;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationDeleteManyAssetsConnectionArgs = {
    where?: Maybe<AssetManyWhereInput>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationPublishManyAssetsConnectionArgs = {
    where?: Maybe<AssetManyWhereInput>;
    from?: Maybe<Stage>;
    to?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
    locales?: Maybe<Array<Locale>>;
    publishBase?: Maybe<Scalars['Boolean']>;
};

export type MutationUnpublishManyAssetsConnectionArgs = {
    where?: Maybe<AssetManyWhereInput>;
    stage?: Maybe<Stage>;
    from?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
    locales?: Maybe<Array<Locale>>;
    unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateManyAssetsArgs = {
    where?: Maybe<AssetManyWhereInput>;
    data: AssetUpdateManyInput;
};

export type MutationDeleteManyAssetsArgs = {
    where?: Maybe<AssetManyWhereInput>;
};

export type MutationPublishManyAssetsArgs = {
    where?: Maybe<AssetManyWhereInput>;
    to?: Array<Stage>;
    locales?: Maybe<Array<Locale>>;
    publishBase?: Maybe<Scalars['Boolean']>;
};

export type MutationUnpublishManyAssetsArgs = {
    where?: Maybe<AssetManyWhereInput>;
    from?: Array<Stage>;
    locales?: Maybe<Array<Locale>>;
    unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateCategoryArgs = {
    data: CategoryCreateInput;
};

export type MutationUpdateCategoryArgs = {
    where: CategoryWhereUniqueInput;
    data: CategoryUpdateInput;
};

export type MutationDeleteCategoryArgs = {
    where: CategoryWhereUniqueInput;
};

export type MutationUpsertCategoryArgs = {
    where: CategoryWhereUniqueInput;
    upsert: CategoryUpsertInput;
};

export type MutationPublishCategoryArgs = {
    where: CategoryWhereUniqueInput;
    to?: Array<Stage>;
};

export type MutationUnpublishCategoryArgs = {
    where: CategoryWhereUniqueInput;
    from?: Array<Stage>;
};

export type MutationUpdateManyCategoriesConnectionArgs = {
    where?: Maybe<CategoryManyWhereInput>;
    data: CategoryUpdateManyInput;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationDeleteManyCategoriesConnectionArgs = {
    where?: Maybe<CategoryManyWhereInput>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationPublishManyCategoriesConnectionArgs = {
    where?: Maybe<CategoryManyWhereInput>;
    from?: Maybe<Stage>;
    to?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUnpublishManyCategoriesConnectionArgs = {
    where?: Maybe<CategoryManyWhereInput>;
    stage?: Maybe<Stage>;
    from?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUpdateManyCategoriesArgs = {
    where?: Maybe<CategoryManyWhereInput>;
    data: CategoryUpdateManyInput;
};

export type MutationDeleteManyCategoriesArgs = {
    where?: Maybe<CategoryManyWhereInput>;
};

export type MutationPublishManyCategoriesArgs = {
    where?: Maybe<CategoryManyWhereInput>;
    to?: Array<Stage>;
};

export type MutationUnpublishManyCategoriesArgs = {
    where?: Maybe<CategoryManyWhereInput>;
    from?: Array<Stage>;
};

export type MutationCreatePostArgs = {
    data: PostCreateInput;
};

export type MutationUpdatePostArgs = {
    where: PostWhereUniqueInput;
    data: PostUpdateInput;
};

export type MutationDeletePostArgs = {
    where: PostWhereUniqueInput;
};

export type MutationUpsertPostArgs = {
    where: PostWhereUniqueInput;
    upsert: PostUpsertInput;
};

export type MutationPublishPostArgs = {
    where: PostWhereUniqueInput;
    to?: Array<Stage>;
};

export type MutationUnpublishPostArgs = {
    where: PostWhereUniqueInput;
    from?: Array<Stage>;
};

export type MutationUpdateManyPostsConnectionArgs = {
    where?: Maybe<PostManyWhereInput>;
    data: PostUpdateManyInput;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationDeleteManyPostsConnectionArgs = {
    where?: Maybe<PostManyWhereInput>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationPublishManyPostsConnectionArgs = {
    where?: Maybe<PostManyWhereInput>;
    from?: Maybe<Stage>;
    to?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUnpublishManyPostsConnectionArgs = {
    where?: Maybe<PostManyWhereInput>;
    stage?: Maybe<Stage>;
    from?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUpdateManyPostsArgs = {
    where?: Maybe<PostManyWhereInput>;
    data: PostUpdateManyInput;
};

export type MutationDeleteManyPostsArgs = {
    where?: Maybe<PostManyWhereInput>;
};

export type MutationPublishManyPostsArgs = {
    where?: Maybe<PostManyWhereInput>;
    to?: Array<Stage>;
};

export type MutationUnpublishManyPostsArgs = {
    where?: Maybe<PostManyWhereInput>;
    from?: Array<Stage>;
};

export type MutationCreateProductArgs = {
    data: ProductCreateInput;
};

export type MutationUpdateProductArgs = {
    where: ProductWhereUniqueInput;
    data: ProductUpdateInput;
};

export type MutationDeleteProductArgs = {
    where: ProductWhereUniqueInput;
};

export type MutationUpsertProductArgs = {
    where: ProductWhereUniqueInput;
    upsert: ProductUpsertInput;
};

export type MutationPublishProductArgs = {
    where: ProductWhereUniqueInput;
    to?: Array<Stage>;
};

export type MutationUnpublishProductArgs = {
    where: ProductWhereUniqueInput;
    from?: Array<Stage>;
};

export type MutationUpdateManyProductsConnectionArgs = {
    where?: Maybe<ProductManyWhereInput>;
    data: ProductUpdateManyInput;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationDeleteManyProductsConnectionArgs = {
    where?: Maybe<ProductManyWhereInput>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationPublishManyProductsConnectionArgs = {
    where?: Maybe<ProductManyWhereInput>;
    from?: Maybe<Stage>;
    to?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUnpublishManyProductsConnectionArgs = {
    where?: Maybe<ProductManyWhereInput>;
    stage?: Maybe<Stage>;
    from?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUpdateManyProductsArgs = {
    where?: Maybe<ProductManyWhereInput>;
    data: ProductUpdateManyInput;
};

export type MutationDeleteManyProductsArgs = {
    where?: Maybe<ProductManyWhereInput>;
};

export type MutationPublishManyProductsArgs = {
    where?: Maybe<ProductManyWhereInput>;
    to?: Array<Stage>;
};

export type MutationUnpublishManyProductsArgs = {
    where?: Maybe<ProductManyWhereInput>;
    from?: Array<Stage>;
};

export type MutationCreateSubcategoryArgs = {
    data: SubcategoryCreateInput;
};

export type MutationUpdateSubcategoryArgs = {
    where: SubcategoryWhereUniqueInput;
    data: SubcategoryUpdateInput;
};

export type MutationDeleteSubcategoryArgs = {
    where: SubcategoryWhereUniqueInput;
};

export type MutationUpsertSubcategoryArgs = {
    where: SubcategoryWhereUniqueInput;
    upsert: SubcategoryUpsertInput;
};

export type MutationPublishSubcategoryArgs = {
    where: SubcategoryWhereUniqueInput;
    to?: Array<Stage>;
};

export type MutationUnpublishSubcategoryArgs = {
    where: SubcategoryWhereUniqueInput;
    from?: Array<Stage>;
};

export type MutationUpdateManySubcategoriesConnectionArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
    data: SubcategoryUpdateManyInput;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationDeleteManySubcategoriesConnectionArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationPublishManySubcategoriesConnectionArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
    from?: Maybe<Stage>;
    to?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUnpublishManySubcategoriesConnectionArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
    stage?: Maybe<Stage>;
    from?: Array<Stage>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['ID']>;
    after?: Maybe<Scalars['ID']>;
};

export type MutationUpdateManySubcategoriesArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
    data: SubcategoryUpdateManyInput;
};

export type MutationDeleteManySubcategoriesArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
};

export type MutationPublishManySubcategoriesArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
    to?: Array<Stage>;
};

export type MutationUnpublishManySubcategoriesArgs = {
    where?: Maybe<SubcategoryManyWhereInput>;
    from?: Array<Stage>;
};

/** An object with an ID */
export type Node = {
    /** The id of the object. */
    id: Scalars['ID'];
    /** The Stage of an object */
    stage: Stage;
};

/** Information about pagination in a connection. */
export type PageInfo = {
    __typename?: 'PageInfo';
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean'];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']>;
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']>;
    /** Number of items in the current page. */
    pageSize?: Maybe<Scalars['Int']>;
};

export type Post = Node & {
    __typename?: 'Post';
    /** System stage field */
    stage: Stage;
    /** Get the document in other stages */
    documentInStages: Array<Post>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** Post's title */
    title: Scalars['String'];
    description: RichText;
    /** Post's priority */
    priority: Scalars['Int'];
    mainImage: Asset;
    images: Array<Asset>;
    /** List of Post versions */
    history: Array<Version>;
};

export type PostDocumentInStagesArgs = {
    stages?: Array<Stage>;
    includeCurrent?: Scalars['Boolean'];
    inheritLocale?: Scalars['Boolean'];
};

export type PostImagesArgs = {
    where?: Maybe<AssetWhereInput>;
    orderBy?: Maybe<AssetOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

export type PostHistoryArgs = {
    limit?: Scalars['Int'];
    skip?: Scalars['Int'];
    stageOverride?: Maybe<Stage>;
};

export type PostConnectInput = {
    /** Document to connect */
    where: PostWhereUniqueInput;
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type PostConnection = {
    __typename?: 'PostConnection';
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** A list of edges. */
    edges: Array<PostEdge>;
    aggregate: Aggregate;
};

export type PostCreateInput = {
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    title: Scalars['String'];
    description: Scalars['RichTextAST'];
    priority: Scalars['Int'];
    mainImage: AssetCreateOneInlineInput;
    images?: Maybe<AssetCreateManyInlineInput>;
};

export type PostCreateManyInlineInput = {
    /** Create and connect multiple existing Post documents */
    create?: Maybe<Array<PostCreateInput>>;
    /** Connect multiple existing Post documents */
    connect?: Maybe<Array<PostWhereUniqueInput>>;
};

export type PostCreateOneInlineInput = {
    /** Create and connect one Post document */
    create?: Maybe<PostCreateInput>;
    /** Connect one existing Post document */
    connect?: Maybe<PostWhereUniqueInput>;
};

/** An edge in a connection. */
export type PostEdge = {
    __typename?: 'PostEdge';
    /** The item at the end of the edge. */
    node: Post;
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
};

/** Identifies documents */
export type PostManyWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<PostWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<PostWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<PostWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    title_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    title_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    title_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    title_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    title_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    title_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    title_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    title_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    title_not_ends_with?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Int']>;
    /** All values that are not equal to given value. */
    priority_not?: Maybe<Scalars['Int']>;
    /** All values that are contained in given list. */
    priority_in?: Maybe<Array<Scalars['Int']>>;
    /** All values that are not contained in given list. */
    priority_not_in?: Maybe<Array<Scalars['Int']>>;
    /** All values less than the given value. */
    priority_lt?: Maybe<Scalars['Int']>;
    /** All values less than or equal the given value. */
    priority_lte?: Maybe<Scalars['Int']>;
    /** All values greater than the given value. */
    priority_gt?: Maybe<Scalars['Int']>;
    /** All values greater than or equal the given value. */
    priority_gte?: Maybe<Scalars['Int']>;
    mainImage?: Maybe<AssetWhereInput>;
    images_every?: Maybe<AssetWhereInput>;
    images_some?: Maybe<AssetWhereInput>;
    images_none?: Maybe<AssetWhereInput>;
};

export enum PostOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    PriorityAsc = 'priority_ASC',
    PriorityDesc = 'priority_DESC',
}

export type PostUpdateInput = {
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['RichTextAST']>;
    priority?: Maybe<Scalars['Int']>;
    mainImage?: Maybe<AssetUpdateOneInlineInput>;
    images?: Maybe<AssetUpdateManyInlineInput>;
};

export type PostUpdateManyInlineInput = {
    /** Create and connect multiple Post documents */
    create?: Maybe<Array<PostCreateInput>>;
    /** Connect multiple existing Post documents */
    connect?: Maybe<Array<PostConnectInput>>;
    /** Override currently-connected documents with multiple existing Post documents */
    set?: Maybe<Array<PostWhereUniqueInput>>;
    /** Update multiple Post documents */
    update?: Maybe<Array<PostUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Post documents */
    upsert?: Maybe<Array<PostUpsertWithNestedWhereUniqueInput>>;
    /** Disconnect multiple Post documents */
    disconnect?: Maybe<Array<PostWhereUniqueInput>>;
    /** Delete multiple Post documents */
    delete?: Maybe<Array<PostWhereUniqueInput>>;
};

export type PostUpdateManyInput = {
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['RichTextAST']>;
    priority?: Maybe<Scalars['Int']>;
};

export type PostUpdateManyWithNestedWhereInput = {
    /** Document search */
    where: PostWhereInput;
    /** Update many input */
    data: PostUpdateManyInput;
};

export type PostUpdateOneInlineInput = {
    /** Create and connect one Post document */
    create?: Maybe<PostCreateInput>;
    /** Update single Post document */
    update?: Maybe<PostUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Post document */
    upsert?: Maybe<PostUpsertWithNestedWhereUniqueInput>;
    /** Connect existing Post document */
    connect?: Maybe<PostWhereUniqueInput>;
    /** Disconnect currently connected Post document */
    disconnect?: Maybe<Scalars['Boolean']>;
    /** Delete currently connected Post document */
    delete?: Maybe<Scalars['Boolean']>;
};

export type PostUpdateWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: PostWhereUniqueInput;
    /** Document to update */
    data: PostUpdateInput;
};

export type PostUpsertInput = {
    /** Create document if it didn't exist */
    create: PostCreateInput;
    /** Update document if it exists */
    update: PostUpdateInput;
};

export type PostUpsertWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: PostWhereUniqueInput;
    /** Upsert data */
    data: PostUpsertInput;
};

/** Identifies documents */
export type PostWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<PostWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<PostWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<PostWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    title_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    title_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    title_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    title_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    title_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    title_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    title_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    title_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    title_not_ends_with?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['Int']>;
    /** All values that are not equal to given value. */
    priority_not?: Maybe<Scalars['Int']>;
    /** All values that are contained in given list. */
    priority_in?: Maybe<Array<Scalars['Int']>>;
    /** All values that are not contained in given list. */
    priority_not_in?: Maybe<Array<Scalars['Int']>>;
    /** All values less than the given value. */
    priority_lt?: Maybe<Scalars['Int']>;
    /** All values less than or equal the given value. */
    priority_lte?: Maybe<Scalars['Int']>;
    /** All values greater than the given value. */
    priority_gt?: Maybe<Scalars['Int']>;
    /** All values greater than or equal the given value. */
    priority_gte?: Maybe<Scalars['Int']>;
    mainImage?: Maybe<AssetWhereInput>;
    images_every?: Maybe<AssetWhereInput>;
    images_some?: Maybe<AssetWhereInput>;
    images_none?: Maybe<AssetWhereInput>;
};

/** References Post record uniquely */
export type PostWhereUniqueInput = {
    id?: Maybe<Scalars['ID']>;
};

/** It indicates the priority of showing news */
export enum Priority {
    Highest = 'Highest',
    Lowest = 'Lowest',
    Medium = 'Medium',
}

/** Offered product */
export type Product = Node & {
    __typename?: 'Product';
    /** System stage field */
    stage: Stage;
    /** Get the document in other stages */
    documentInStages: Array<Product>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
    description: RichText;
    price: Scalars['Float'];
    oldPrice?: Maybe<Scalars['Float']>;
    subcategory?: Maybe<Subcategory>;
    /** It indicates if a product is marked as new or not */
    latest: Scalars['Boolean'];
    mainImage: Asset;
    images: Array<Asset>;
    /** List of Product versions */
    history: Array<Version>;
};

/** Offered product */
export type ProductDocumentInStagesArgs = {
    stages?: Array<Stage>;
    includeCurrent?: Scalars['Boolean'];
    inheritLocale?: Scalars['Boolean'];
};

/** Offered product */
export type ProductImagesArgs = {
    where?: Maybe<AssetWhereInput>;
    orderBy?: Maybe<AssetOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Offered product */
export type ProductHistoryArgs = {
    limit?: Scalars['Int'];
    skip?: Scalars['Int'];
    stageOverride?: Maybe<Stage>;
};

export type ProductConnectInput = {
    /** Document to connect */
    where: ProductWhereUniqueInput;
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type ProductConnection = {
    __typename?: 'ProductConnection';
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** A list of edges. */
    edges: Array<ProductEdge>;
    aggregate: Aggregate;
};

export type ProductCreateInput = {
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
    description: Scalars['RichTextAST'];
    price: Scalars['Float'];
    oldPrice?: Maybe<Scalars['Float']>;
    subcategory?: Maybe<SubcategoryCreateOneInlineInput>;
    latest: Scalars['Boolean'];
    mainImage: AssetCreateOneInlineInput;
    images?: Maybe<AssetCreateManyInlineInput>;
};

export type ProductCreateManyInlineInput = {
    /** Create and connect multiple existing Product documents */
    create?: Maybe<Array<ProductCreateInput>>;
    /** Connect multiple existing Product documents */
    connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateOneInlineInput = {
    /** Create and connect one Product document */
    create?: Maybe<ProductCreateInput>;
    /** Connect one existing Product document */
    connect?: Maybe<ProductWhereUniqueInput>;
};

/** An edge in a connection. */
export type ProductEdge = {
    __typename?: 'ProductEdge';
    /** The item at the end of the edge. */
    node: Product;
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
};

/** Identifies documents */
export type ProductManyWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<ProductWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<ProductWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<ProductWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    name_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    name_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    name_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    name_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    name_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    name_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    name_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    name_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    name_not_ends_with?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    /** All values that are not equal to given value. */
    price_not?: Maybe<Scalars['Float']>;
    /** All values that are contained in given list. */
    price_in?: Maybe<Array<Scalars['Float']>>;
    /** All values that are not contained in given list. */
    price_not_in?: Maybe<Array<Scalars['Float']>>;
    /** All values less than the given value. */
    price_lt?: Maybe<Scalars['Float']>;
    /** All values less than or equal the given value. */
    price_lte?: Maybe<Scalars['Float']>;
    /** All values greater than the given value. */
    price_gt?: Maybe<Scalars['Float']>;
    /** All values greater than or equal the given value. */
    price_gte?: Maybe<Scalars['Float']>;
    oldPrice?: Maybe<Scalars['Float']>;
    /** All values that are not equal to given value. */
    oldPrice_not?: Maybe<Scalars['Float']>;
    /** All values that are contained in given list. */
    oldPrice_in?: Maybe<Array<Scalars['Float']>>;
    /** All values that are not contained in given list. */
    oldPrice_not_in?: Maybe<Array<Scalars['Float']>>;
    /** All values less than the given value. */
    oldPrice_lt?: Maybe<Scalars['Float']>;
    /** All values less than or equal the given value. */
    oldPrice_lte?: Maybe<Scalars['Float']>;
    /** All values greater than the given value. */
    oldPrice_gt?: Maybe<Scalars['Float']>;
    /** All values greater than or equal the given value. */
    oldPrice_gte?: Maybe<Scalars['Float']>;
    subcategory?: Maybe<SubcategoryWhereInput>;
    latest?: Maybe<Scalars['Boolean']>;
    /** All values that are not equal to given value. */
    latest_not?: Maybe<Scalars['Boolean']>;
    mainImage?: Maybe<AssetWhereInput>;
    images_every?: Maybe<AssetWhereInput>;
    images_some?: Maybe<AssetWhereInput>;
    images_none?: Maybe<AssetWhereInput>;
};

export enum ProductOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PriceAsc = 'price_ASC',
    PriceDesc = 'price_DESC',
    OldPriceAsc = 'oldPrice_ASC',
    OldPriceDesc = 'oldPrice_DESC',
    LatestAsc = 'latest_ASC',
    LatestDesc = 'latest_DESC',
}

export type ProductUpdateInput = {
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['RichTextAST']>;
    price?: Maybe<Scalars['Float']>;
    oldPrice?: Maybe<Scalars['Float']>;
    subcategory?: Maybe<SubcategoryUpdateOneInlineInput>;
    latest?: Maybe<Scalars['Boolean']>;
    mainImage?: Maybe<AssetUpdateOneInlineInput>;
    images?: Maybe<AssetUpdateManyInlineInput>;
};

export type ProductUpdateManyInlineInput = {
    /** Create and connect multiple Product documents */
    create?: Maybe<Array<ProductCreateInput>>;
    /** Connect multiple existing Product documents */
    connect?: Maybe<Array<ProductConnectInput>>;
    /** Override currently-connected documents with multiple existing Product documents */
    set?: Maybe<Array<ProductWhereUniqueInput>>;
    /** Update multiple Product documents */
    update?: Maybe<Array<ProductUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Product documents */
    upsert?: Maybe<Array<ProductUpsertWithNestedWhereUniqueInput>>;
    /** Disconnect multiple Product documents */
    disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
    /** Delete multiple Product documents */
    delete?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductUpdateManyInput = {
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['RichTextAST']>;
    price?: Maybe<Scalars['Float']>;
    oldPrice?: Maybe<Scalars['Float']>;
    latest?: Maybe<Scalars['Boolean']>;
};

export type ProductUpdateManyWithNestedWhereInput = {
    /** Document search */
    where: ProductWhereInput;
    /** Update many input */
    data: ProductUpdateManyInput;
};

export type ProductUpdateOneInlineInput = {
    /** Create and connect one Product document */
    create?: Maybe<ProductCreateInput>;
    /** Update single Product document */
    update?: Maybe<ProductUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Product document */
    upsert?: Maybe<ProductUpsertWithNestedWhereUniqueInput>;
    /** Connect existing Product document */
    connect?: Maybe<ProductWhereUniqueInput>;
    /** Disconnect currently connected Product document */
    disconnect?: Maybe<Scalars['Boolean']>;
    /** Delete currently connected Product document */
    delete?: Maybe<Scalars['Boolean']>;
};

export type ProductUpdateWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: ProductWhereUniqueInput;
    /** Document to update */
    data: ProductUpdateInput;
};

export type ProductUpsertInput = {
    /** Create document if it didn't exist */
    create: ProductCreateInput;
    /** Update document if it exists */
    update: ProductUpdateInput;
};

export type ProductUpsertWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: ProductWhereUniqueInput;
    /** Upsert data */
    data: ProductUpsertInput;
};

/** Identifies documents */
export type ProductWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<ProductWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<ProductWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<ProductWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    name_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    name_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    name_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    name_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    name_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    name_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    name_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    name_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    name_not_ends_with?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Float']>;
    /** All values that are not equal to given value. */
    price_not?: Maybe<Scalars['Float']>;
    /** All values that are contained in given list. */
    price_in?: Maybe<Array<Scalars['Float']>>;
    /** All values that are not contained in given list. */
    price_not_in?: Maybe<Array<Scalars['Float']>>;
    /** All values less than the given value. */
    price_lt?: Maybe<Scalars['Float']>;
    /** All values less than or equal the given value. */
    price_lte?: Maybe<Scalars['Float']>;
    /** All values greater than the given value. */
    price_gt?: Maybe<Scalars['Float']>;
    /** All values greater than or equal the given value. */
    price_gte?: Maybe<Scalars['Float']>;
    oldPrice?: Maybe<Scalars['Float']>;
    /** All values that are not equal to given value. */
    oldPrice_not?: Maybe<Scalars['Float']>;
    /** All values that are contained in given list. */
    oldPrice_in?: Maybe<Array<Scalars['Float']>>;
    /** All values that are not contained in given list. */
    oldPrice_not_in?: Maybe<Array<Scalars['Float']>>;
    /** All values less than the given value. */
    oldPrice_lt?: Maybe<Scalars['Float']>;
    /** All values less than or equal the given value. */
    oldPrice_lte?: Maybe<Scalars['Float']>;
    /** All values greater than the given value. */
    oldPrice_gt?: Maybe<Scalars['Float']>;
    /** All values greater than or equal the given value. */
    oldPrice_gte?: Maybe<Scalars['Float']>;
    subcategory?: Maybe<SubcategoryWhereInput>;
    latest?: Maybe<Scalars['Boolean']>;
    /** All values that are not equal to given value. */
    latest_not?: Maybe<Scalars['Boolean']>;
    mainImage?: Maybe<AssetWhereInput>;
    images_every?: Maybe<AssetWhereInput>;
    images_some?: Maybe<AssetWhereInput>;
    images_none?: Maybe<AssetWhereInput>;
};

/** References Product record uniquely */
export type ProductWhereUniqueInput = {
    id?: Maybe<Scalars['ID']>;
};

export type PublishLocaleInput = {
    /** Locales to publish */
    locale: Locale;
    /** Stages to publish selected locales to */
    stages: Array<Stage>;
};

export type Query = {
    __typename?: 'Query';
    /** Fetches an object given its ID */
    node?: Maybe<Node>;
    /** Retrieve multiple advertises */
    advertises: Array<Advertise>;
    /** Retrieve a single advertise */
    advertise?: Maybe<Advertise>;
    /** Retrieve multiple advertises using the Relay connection interface */
    advertisesConnection: AdvertiseConnection;
    /** Retrieve document version */
    advertiseVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple assets */
    assets: Array<Asset>;
    /** Retrieve a single asset */
    asset?: Maybe<Asset>;
    /** Retrieve multiple assets using the Relay connection interface */
    assetsConnection: AssetConnection;
    /** Retrieve document version */
    assetVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple categories */
    categories: Array<Category>;
    /** Retrieve a single category */
    category?: Maybe<Category>;
    /** Retrieve multiple categories using the Relay connection interface */
    categoriesConnection: CategoryConnection;
    /** Retrieve document version */
    categoryVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple posts */
    posts: Array<Post>;
    /** Retrieve a single post */
    post?: Maybe<Post>;
    /** Retrieve multiple posts using the Relay connection interface */
    postsConnection: PostConnection;
    /** Retrieve document version */
    postVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple products */
    products: Array<Product>;
    /** Retrieve a single product */
    product?: Maybe<Product>;
    /** Retrieve multiple products using the Relay connection interface */
    productsConnection: ProductConnection;
    /** Retrieve document version */
    productVersion?: Maybe<DocumentVersion>;
    /** Retrieve multiple subcategories */
    subcategories: Array<Subcategory>;
    /** Retrieve a single subcategory */
    subcategory?: Maybe<Subcategory>;
    /** Retrieve multiple subcategories using the Relay connection interface */
    subcategoriesConnection: SubcategoryConnection;
    /** Retrieve document version */
    subcategoryVersion?: Maybe<DocumentVersion>;
};

export type QueryNodeArgs = {
    id: Scalars['ID'];
    stage?: Stage;
    locales?: Array<Locale>;
};

export type QueryAdvertisesArgs = {
    where?: Maybe<AdvertiseWhereInput>;
    orderBy?: Maybe<AdvertiseOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryAdvertiseArgs = {
    where: AdvertiseWhereUniqueInput;
    stage?: Stage;
};

export type QueryAdvertisesConnectionArgs = {
    where?: Maybe<AdvertiseWhereInput>;
    orderBy?: Maybe<AdvertiseOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryAdvertiseVersionArgs = {
    where: VersionWhereInput;
};

export type QueryAssetsArgs = {
    where?: Maybe<AssetWhereInput>;
    orderBy?: Maybe<AssetOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
    locales?: Array<Locale>;
};

export type QueryAssetArgs = {
    where: AssetWhereUniqueInput;
    stage?: Stage;
    locales?: Array<Locale>;
};

export type QueryAssetsConnectionArgs = {
    where?: Maybe<AssetWhereInput>;
    orderBy?: Maybe<AssetOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
    locales?: Array<Locale>;
};

export type QueryAssetVersionArgs = {
    where: VersionWhereInput;
};

export type QueryCategoriesArgs = {
    where?: Maybe<CategoryWhereInput>;
    orderBy?: Maybe<CategoryOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryCategoryArgs = {
    where: CategoryWhereUniqueInput;
    stage?: Stage;
};

export type QueryCategoriesConnectionArgs = {
    where?: Maybe<CategoryWhereInput>;
    orderBy?: Maybe<CategoryOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryCategoryVersionArgs = {
    where: VersionWhereInput;
};

export type QueryPostsArgs = {
    where?: Maybe<PostWhereInput>;
    orderBy?: Maybe<PostOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryPostArgs = {
    where: PostWhereUniqueInput;
    stage?: Stage;
};

export type QueryPostsConnectionArgs = {
    where?: Maybe<PostWhereInput>;
    orderBy?: Maybe<PostOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryPostVersionArgs = {
    where: VersionWhereInput;
};

export type QueryProductsArgs = {
    where?: Maybe<ProductWhereInput>;
    orderBy?: Maybe<ProductOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryProductArgs = {
    where: ProductWhereUniqueInput;
    stage?: Stage;
};

export type QueryProductsConnectionArgs = {
    where?: Maybe<ProductWhereInput>;
    orderBy?: Maybe<ProductOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QueryProductVersionArgs = {
    where: VersionWhereInput;
};

export type QuerySubcategoriesArgs = {
    where?: Maybe<SubcategoryWhereInput>;
    orderBy?: Maybe<SubcategoryOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QuerySubcategoryArgs = {
    where: SubcategoryWhereUniqueInput;
    stage?: Stage;
};

export type QuerySubcategoriesConnectionArgs = {
    where?: Maybe<SubcategoryWhereInput>;
    orderBy?: Maybe<SubcategoryOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    stage?: Stage;
};

export type QuerySubcategoryVersionArgs = {
    where: VersionWhereInput;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
    __typename?: 'RGBA';
    r: Scalars['RGBAHue'];
    g: Scalars['RGBAHue'];
    b: Scalars['RGBAHue'];
    a: Scalars['RGBATransparency'];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
    r: Scalars['RGBAHue'];
    g: Scalars['RGBAHue'];
    b: Scalars['RGBAHue'];
    a: Scalars['RGBATransparency'];
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
    __typename?: 'RichText';
    /** Returns AST representation */
    raw: Scalars['RichTextAST'];
    /** Returns HTMl representation */
    html: Scalars['String'];
    /** Returns Markdown representation */
    markdown: Scalars['String'];
    /** Returns plain-text contents of RichText */
    text: Scalars['String'];
};

/** Stage system enumeration */
export enum Stage {
    /** The Published stage is where you can publish your content to. */
    Published = 'PUBLISHED',
    /** The Draft is the default stage for all your content. */
    Draft = 'DRAFT',
}

/** Subcategory of an offered product */
export type Subcategory = Node & {
    __typename?: 'Subcategory';
    /** System stage field */
    stage: Stage;
    /** Get the document in other stages */
    documentInStages: Array<Subcategory>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** Name of subcategory */
    name: Scalars['String'];
    /** Category of an offered product */
    category?: Maybe<Category>;
    product: Array<Product>;
    /** List of Subcategory versions */
    history: Array<Version>;
};

/** Subcategory of an offered product */
export type SubcategoryDocumentInStagesArgs = {
    stages?: Array<Stage>;
    includeCurrent?: Scalars['Boolean'];
    inheritLocale?: Scalars['Boolean'];
};

/** Subcategory of an offered product */
export type SubcategoryProductArgs = {
    where?: Maybe<ProductWhereInput>;
    orderBy?: Maybe<ProductOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** Subcategory of an offered product */
export type SubcategoryHistoryArgs = {
    limit?: Scalars['Int'];
    skip?: Scalars['Int'];
    stageOverride?: Maybe<Stage>;
};

export type SubcategoryConnectInput = {
    /** Document to connect */
    where: SubcategoryWhereUniqueInput;
    /** Allow to specify document position in list of connected documents, will default to appending at end of list */
    position?: Maybe<ConnectPositionInput>;
};

/** A connection to a list of items. */
export type SubcategoryConnection = {
    __typename?: 'SubcategoryConnection';
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** A list of edges. */
    edges: Array<SubcategoryEdge>;
    aggregate: Aggregate;
};

export type SubcategoryCreateInput = {
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
    category?: Maybe<CategoryCreateOneInlineInput>;
    product?: Maybe<ProductCreateManyInlineInput>;
};

export type SubcategoryCreateManyInlineInput = {
    /** Create and connect multiple existing Subcategory documents */
    create?: Maybe<Array<SubcategoryCreateInput>>;
    /** Connect multiple existing Subcategory documents */
    connect?: Maybe<Array<SubcategoryWhereUniqueInput>>;
};

export type SubcategoryCreateOneInlineInput = {
    /** Create and connect one Subcategory document */
    create?: Maybe<SubcategoryCreateInput>;
    /** Connect one existing Subcategory document */
    connect?: Maybe<SubcategoryWhereUniqueInput>;
};

/** An edge in a connection. */
export type SubcategoryEdge = {
    __typename?: 'SubcategoryEdge';
    /** The item at the end of the edge. */
    node: Subcategory;
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
};

/** Identifies documents */
export type SubcategoryManyWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<SubcategoryWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<SubcategoryWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<SubcategoryWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    name_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    name_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    name_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    name_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    name_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    name_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    name_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    name_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    name_not_ends_with?: Maybe<Scalars['String']>;
    category?: Maybe<CategoryWhereInput>;
    product_every?: Maybe<ProductWhereInput>;
    product_some?: Maybe<ProductWhereInput>;
    product_none?: Maybe<ProductWhereInput>;
};

export enum SubcategoryOrderByInput {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
}

export type SubcategoryUpdateInput = {
    name?: Maybe<Scalars['String']>;
    category?: Maybe<CategoryUpdateOneInlineInput>;
    product?: Maybe<ProductUpdateManyInlineInput>;
};

export type SubcategoryUpdateManyInlineInput = {
    /** Create and connect multiple Subcategory documents */
    create?: Maybe<Array<SubcategoryCreateInput>>;
    /** Connect multiple existing Subcategory documents */
    connect?: Maybe<Array<SubcategoryConnectInput>>;
    /** Override currently-connected documents with multiple existing Subcategory documents */
    set?: Maybe<Array<SubcategoryWhereUniqueInput>>;
    /** Update multiple Subcategory documents */
    update?: Maybe<Array<SubcategoryUpdateWithNestedWhereUniqueInput>>;
    /** Upsert multiple Subcategory documents */
    upsert?: Maybe<Array<SubcategoryUpsertWithNestedWhereUniqueInput>>;
    /** Disconnect multiple Subcategory documents */
    disconnect?: Maybe<Array<SubcategoryWhereUniqueInput>>;
    /** Delete multiple Subcategory documents */
    delete?: Maybe<Array<SubcategoryWhereUniqueInput>>;
};

export type SubcategoryUpdateManyInput = {
    /** No fields in updateMany data input */
    _?: Maybe<Scalars['String']>;
};

export type SubcategoryUpdateManyWithNestedWhereInput = {
    /** Document search */
    where: SubcategoryWhereInput;
    /** Update many input */
    data: SubcategoryUpdateManyInput;
};

export type SubcategoryUpdateOneInlineInput = {
    /** Create and connect one Subcategory document */
    create?: Maybe<SubcategoryCreateInput>;
    /** Update single Subcategory document */
    update?: Maybe<SubcategoryUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Subcategory document */
    upsert?: Maybe<SubcategoryUpsertWithNestedWhereUniqueInput>;
    /** Connect existing Subcategory document */
    connect?: Maybe<SubcategoryWhereUniqueInput>;
    /** Disconnect currently connected Subcategory document */
    disconnect?: Maybe<Scalars['Boolean']>;
    /** Delete currently connected Subcategory document */
    delete?: Maybe<Scalars['Boolean']>;
};

export type SubcategoryUpdateWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: SubcategoryWhereUniqueInput;
    /** Document to update */
    data: SubcategoryUpdateInput;
};

export type SubcategoryUpsertInput = {
    /** Create document if it didn't exist */
    create: SubcategoryCreateInput;
    /** Update document if it exists */
    update: SubcategoryUpdateInput;
};

export type SubcategoryUpsertWithNestedWhereUniqueInput = {
    /** Unique document search */
    where: SubcategoryWhereUniqueInput;
    /** Upsert data */
    data: SubcategoryUpsertInput;
};

/** Identifies documents */
export type SubcategoryWhereInput = {
    /** Contains search across all appropriate fields. */
    _search?: Maybe<Scalars['String']>;
    /** Logical AND on all given filters. */
    AND?: Maybe<Array<SubcategoryWhereInput>>;
    /** Logical OR on all given filters. */
    OR?: Maybe<Array<SubcategoryWhereInput>>;
    /** Logical NOT on all given filters combined by AND. */
    NOT?: Maybe<Array<SubcategoryWhereInput>>;
    id?: Maybe<Scalars['ID']>;
    /** All values that are not equal to given value. */
    id_not?: Maybe<Scalars['ID']>;
    /** All values that are contained in given list. */
    id_in?: Maybe<Array<Scalars['ID']>>;
    /** All values that are not contained in given list. */
    id_not_in?: Maybe<Array<Scalars['ID']>>;
    /** All values containing the given string. */
    id_contains?: Maybe<Scalars['ID']>;
    /** All values not containing the given string. */
    id_not_contains?: Maybe<Scalars['ID']>;
    /** All values starting with the given string. */
    id_starts_with?: Maybe<Scalars['ID']>;
    /** All values not starting with the given string. */
    id_not_starts_with?: Maybe<Scalars['ID']>;
    /** All values ending with the given string. */
    id_ends_with?: Maybe<Scalars['ID']>;
    /** All values not ending with the given string */
    id_not_ends_with?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    createdAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    createdAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    createdAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    createdAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    createdAt_gte?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    updatedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    updatedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    updatedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    updatedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    updatedAt_gte?: Maybe<Scalars['DateTime']>;
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** All values that are not equal to given value. */
    publishedAt_not?: Maybe<Scalars['DateTime']>;
    /** All values that are contained in given list. */
    publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values that are not contained in given list. */
    publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
    /** All values less than the given value. */
    publishedAt_lt?: Maybe<Scalars['DateTime']>;
    /** All values less than or equal the given value. */
    publishedAt_lte?: Maybe<Scalars['DateTime']>;
    /** All values greater than the given value. */
    publishedAt_gt?: Maybe<Scalars['DateTime']>;
    /** All values greater than or equal the given value. */
    publishedAt_gte?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    /** All values that are not equal to given value. */
    name_not?: Maybe<Scalars['String']>;
    /** All values that are contained in given list. */
    name_in?: Maybe<Array<Scalars['String']>>;
    /** All values that are not contained in given list. */
    name_not_in?: Maybe<Array<Scalars['String']>>;
    /** All values containing the given string. */
    name_contains?: Maybe<Scalars['String']>;
    /** All values not containing the given string. */
    name_not_contains?: Maybe<Scalars['String']>;
    /** All values starting with the given string. */
    name_starts_with?: Maybe<Scalars['String']>;
    /** All values not starting with the given string. */
    name_not_starts_with?: Maybe<Scalars['String']>;
    /** All values ending with the given string. */
    name_ends_with?: Maybe<Scalars['String']>;
    /** All values not ending with the given string */
    name_not_ends_with?: Maybe<Scalars['String']>;
    category?: Maybe<CategoryWhereInput>;
    product_every?: Maybe<ProductWhereInput>;
    product_some?: Maybe<ProductWhereInput>;
    product_none?: Maybe<ProductWhereInput>;
};

/** References Subcategory record uniquely */
export type SubcategoryWhereUniqueInput = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export enum SystemDateTimeFieldVariation {
    Base = 'BASE',
    Localization = 'LOCALIZATION',
    Combined = 'COMBINED',
}

export type UnpublishLocaleInput = {
    /** Locales to unpublish */
    locale: Locale;
    /** Stages to unpublish selected locales from */
    stages: Array<Stage>;
};

export type Version = {
    __typename?: 'Version';
    id: Scalars['ID'];
    stage: Stage;
    revision: Scalars['Int'];
    createdAt: Scalars['DateTime'];
};

export type VersionWhereInput = {
    id: Scalars['ID'];
    stage: Stage;
    revision: Scalars['Int'];
};

export type ProductsQueryVariables = Exact<{
    where?: Maybe<ProductWhereInput>;
    orderBy?: Maybe<ProductOrderByInput>;
    skip?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
}>;

export type ProductsQuery = { __typename?: 'Query' } & {
    products: Array<
        { __typename?: 'Product' } & Pick<Product, 'id' | 'name' | 'createdAt' | 'price' | 'oldPrice' | 'latest'> & {
                mainImage: { __typename?: 'Asset' } & Pick<Asset, 'url'>;
            }
    >;
    allItems: Array<{ __typename?: 'Product' } & Pick<Product, 'id'>>;
};

export const ProductsDocument = gql`
    query products($where: ProductWhereInput, $orderBy: ProductOrderByInput, $skip: Int, $first: Int) {
        products(where: $where, orderBy: $orderBy, skip: $skip, first: $first) {
            id
            name
            createdAt
            price
            oldPrice
            mainImage {
                url(transformation: { image: { resize: { height: 600, width: 600 } } })
            }
            latest
        }
        allItems: products(where: $where, orderBy: $orderBy) {
            id
        }
    }
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
    return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
}
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
    return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
