import React from 'react';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { ThemedProps } from '../styles/theme';
import IconArrow from '../static/icons/icon-arrow.inline.svg';
import IconDoubleArrow from '../static/icons/icon-double-arrow.inline.svg';
import { Link } from 'gatsby';

interface HidingPaginatorElementProps extends ThemedProps {
    disabled?: boolean;
}

const PaginatorContainer = styled.div<HidingPaginatorElementProps>`
    display: ${({ disabled }: HidingPaginatorElementProps) => (disabled ? 'none' : 'flex')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }: HidingPaginatorElementProps) => theme.colors.white};
    border: 1px solid ${({ theme }: HidingPaginatorElementProps) => theme.colors.gray};
    border-radius: ${({ theme }: HidingPaginatorElementProps) => theme.borders.radius};
    padding: 0.75rem 1rem;
    flex: 1;
`;

const PaginatorControlsContainer = styled.div<HidingPaginatorElementProps>`
    display: flex;
    align-items: center;
`;

interface PaginatorIconWrapperProps extends ThemedProps, HidingPaginatorElementProps {
    rotation: string;
}

const customIconStyle = css<PaginatorIconWrapperProps>`
    display: ${({ disabled }: HidingPaginatorElementProps) => (disabled ? 'none' : 'block')};
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(${({ rotation }: PaginatorIconWrapperProps) => rotation})};
    fill: ${({ theme }: PaginatorIconWrapperProps) => theme.colors.black}};
      &:hover {
        fill: ${({ theme }: ThemedProps) => theme.colors.darkgray};
    }
`;

const CustomIconArrow = styled(IconArrow)<PaginatorIconWrapperProps>`
    ${customIconStyle}
`;

const CustomIconDoubleArrow = styled(IconDoubleArrow)`
    ${customIconStyle}
`;

const PaginatorIndicator = styled.div<ThemedProps>`
    margin: 0 0.25rem;
`;

interface Props {
    currentPageNumber: number;
    totalPagesNumber: number;
    prepareCurrentPageUrl: (pageNumber: string) => string;
}

const Paginator: FunctionComponent<Props> = ({ currentPageNumber, totalPagesNumber, prepareCurrentPageUrl }) => {
    const firstPageNumberUrl = prepareCurrentPageUrl(`${1}`);
    const lastPageNumberUrl = prepareCurrentPageUrl(`${totalPagesNumber}`);
    const previousPageNumberUrl = prepareCurrentPageUrl(`${currentPageNumber - 1}`);
    const nextPageNumberUrl = prepareCurrentPageUrl(`${currentPageNumber + 1}`);

    return (
        <PaginatorContainer disabled={totalPagesNumber <= 1}>
            <PaginatorIndicator>
                {currentPageNumber} z {totalPagesNumber}
            </PaginatorIndicator>
            <PaginatorControlsContainer>
                <Link to={firstPageNumberUrl}>
                    <CustomIconDoubleArrow rotation="-180deg" disabled={currentPageNumber === 1} />
                </Link>
                <Link to={previousPageNumberUrl}>
                    <CustomIconArrow rotation="90deg" disabled={currentPageNumber === 1} />
                </Link>
                <PaginatorIndicator>{currentPageNumber}</PaginatorIndicator>
                <Link to={nextPageNumberUrl}>
                    <CustomIconArrow rotation="-90deg" disabled={currentPageNumber === totalPagesNumber} />
                </Link>
                <Link to={lastPageNumberUrl}>
                    <CustomIconDoubleArrow rotation="0deg" disabled={currentPageNumber === totalPagesNumber} />
                </Link>
            </PaginatorControlsContainer>
        </PaginatorContainer>
    );
};

export default Paginator;
