import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import CheckedIconUrl from '../static/icons/icon-checked.svg';

const CheckboxLabel = styled.label<ThemedProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })<ThemedProps>`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

interface CheckboxSquareProps extends ThemedProps {
    checked: boolean;
}

const CheckboxSquare = styled.div<CheckboxSquareProps>`
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    background-image: ${({ checked }: CheckboxSquareProps) => (checked ? `url(${CheckedIconUrl})` : 'unset')};
    background-color: ${({ theme, checked }: CheckboxSquareProps) =>
        checked ? theme.colors.yellow : theme.colors.gray};
    border-radius: ${({ theme }: CheckboxSquareProps) => theme.borders.radiusSmall};

    ${CheckboxInput}:focus + & {
        border: 2px solid ${({ theme }: CheckboxSquareProps) => theme.colors.black};
    }
`;

interface Props {
    checked: boolean;
    onChange?: (checked: boolean) => void;
    label: string;
}

const Checkbox: FunctionComponent<Props> = ({ onChange, checked, label }) => {
    return (
        <CheckboxLabel>
            <CheckboxInput checked={checked} onChange={(e) => onChange && onChange(e.target.checked)} />
            <CheckboxSquare checked={checked} />
            <span>{label}</span>
        </CheckboxLabel>
    );
};

export default Checkbox;
