import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import ArrowIconUrl from '../static/icons/icon-arrow.svg';

const DefaultSelectContainer = styled.label<ThemedProps>`
    position: relative;
    width: 100%;
`;

const DefaultSelect = styled.select<ThemedProps>`
    height: 100%;
    width: 100%;
    min-width: max-content;
    border: ${(props: ThemedProps) => `1px solid ${props.theme.colors.gray}`};
    border-radius: ${(props: ThemedProps) => props.theme.borders.radiusSmall};
    background-color: ${(props: ThemedProps) => props.theme.colors.white};
    padding: 0.75rem 3rem 0.75rem 1rem;
    appearance: none;
    background-image: url(${ArrowIconUrl});
    background-position: right 0.5rem top 50%, 0 0;
    background-size: 2rem 2rem;
    background-repeat: no-repeat, repeat;
    appearance: none;

    &:focus {
        outline-color: ${(props: ThemedProps) => props.theme.colors.yellow};
    }

    &::placeholder {
        color: ${(props: ThemedProps) => props.theme.colors.darkgray};
    }
`;

const DefaultSelectLabel = styled.span<ThemedProps>`
    padding: 0 0.25em;
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.small};
    color: ${(props: ThemedProps) => props.theme.colors.darkgray};
    position: absolute;
    top: -0.5em;
    left: 0.75em;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        top: 0.5em;
        background-color: ${(props: ThemedProps) => props.theme.colors.white};
        z-index: -1;
    }
`;

interface Props {
    label?: string;
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    required?: boolean;
    name?: string;
}

const Select: FunctionComponent<Props> = ({
    label,
    onChange,
    placeholder,
    required = false,
    name,
    children,
    value,
}) => {
    return (
        <DefaultSelectContainer>
            {label && <DefaultSelectLabel>{label}</DefaultSelectLabel>}
            <DefaultSelect
                value={value}
                onChange={(event) => onChange && onChange(event.target.value)}
                placeholder={placeholder}
                required={required}
                name={name}
            >
                {children}
            </DefaultSelect>
        </DefaultSelectContainer>
    );
};
export default Select;
