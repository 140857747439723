import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/theme';
import { checkIfProductIsNew, formatPrice } from '../../utils/utils';
import Indicator from '../indicator';
import { DISCOUNT_INDICATOR_TITLE, NEW_INDICATOR_TITLE } from '../../common';
import AspectRatioImage from '../aspect-ratio-image';

const ProductCardContainer = styled.article<ThemedProps>`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${({ theme }: ThemedProps) => theme.colors.white};
    color: ${({ theme }: ThemedProps) => theme.colors.black};
    border-radius: ${({ theme }: ThemedProps) => theme.borders.radius};
    border: 1px solid ${({ theme }: ThemedProps) => theme.colors.gray};
    position: relative;
    text-align: center;
    transition: box-shadow 0.2s ease-in-out;
    overflow: hidden;

    &:hover {
        box-shadow: ${({ theme }: ThemedProps) => theme.effects.shadow};
    }
`;

const ProductCardTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;

const ProductCardPreviousPrice = styled.p<ThemedProps>`
    color: ${({ theme }: ThemedProps) => theme.colors.darkgray};
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.small};
    text-decoration-line: line-through;
    margin: 0.25em;
`;

const PriceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
`;

const ProductCardCurrentPrice = styled.p<ThemedProps>`
    color: ${({ theme }: ThemedProps) => theme.colors.yellow};
    font-weight: ${({ theme }: ThemedProps) => theme.typography.weights.bold};
    margin: 0.25em;
`;

const ProductCardName = styled.h4<ThemedProps>`
    margin: 0.25em;
`;

const IndicatorsContainer = styled.div`
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
        margin-bottom: 0.5rem;
    }
`;

interface Props {
    name: string;
    imageUrl: string;
    latest: boolean;
    createdAt: Date;
    price: number;
    oldPrice?: number | undefined | null;
}

const ProductCard: FunctionComponent<Props> = ({ name, imageUrl, latest, createdAt, price, oldPrice }) => {
    return (
        <ProductCardContainer>
            {
                <IndicatorsContainer>
                    {(latest || checkIfProductIsNew(createdAt)) && <Indicator title={NEW_INDICATOR_TITLE} />}
                    {oldPrice && oldPrice > price && <Indicator title={DISCOUNT_INDICATOR_TITLE} />}
                </IndicatorsContainer>
            }
            <AspectRatioImage ratioWidth={3} ratioHeight={4} src={imageUrl} alt={name} />
            <ProductCardTextContainer>
                <ProductCardName>{name}</ProductCardName>
                <PriceContainer>
                    <ProductCardCurrentPrice>{formatPrice(price)}</ProductCardCurrentPrice>
                    {oldPrice && <ProductCardPreviousPrice>{formatPrice(oldPrice)}</ProductCardPreviousPrice>}
                </PriceContainer>
            </ProductCardTextContainer>
        </ProductCardContainer>
    );
};
export default ProductCard;
