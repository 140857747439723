import React, { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import Input from './input';
import Select from './select';
import { SORT_CRITERIA_VALUES } from '../common';
import Button from './button';
import SearchIcon from '../static/icons/icon-search.inline.svg';
import Ripple from './ripple';
import { navigate } from '@reach/router';

const Container = styled.div<ThemedProps>`
    display: grid;
    gap: 1rem;
    grid-template-columns: 2fr 1fr;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        grid-template-columns: unset;
    }
`;

const Form = styled.form`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

const SearchButton = styled(Button)<ThemedProps>`
    padding: 0 1rem;
    display: flex;
    align-items: center;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: ${({ theme }: ThemedProps) => theme.borders.radiusSmall};
    border-bottom-right-radius: ${({ theme }: ThemedProps) => theme.borders.radiusSmall};
    box-shadow: unset;
`;

const SearchInput = styled(Input)`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
`;

const CustomSearchIcon = styled(SearchIcon)`
    width: 2rem;
    height: 2rem;
`;

const { CREATED_AT_DESC, CREATED_AT_ASC, PRICE_ASC, PRICE_DES } = SORT_CRITERIA_VALUES;

interface Props {
    searchPhrase?: string;
    sortCriteria?: string;
    prepareSearchPhraseUrl: (selectedSearchPhrase: string | undefined) => string;
    prepareSortCriteria: (selectedSortCriteria: string | undefined) => string;
}

const SearchAndSort: FunctionComponent<Props> = ({
    searchPhrase,
    sortCriteria,
    prepareSearchPhraseUrl,
    prepareSortCriteria,
}) => {
    const [currentSearchPhrase, setCurrentSearchPhrase] = useState(searchPhrase);

    useEffect(() => {
        setCurrentSearchPhrase((prevState) => (prevState === searchPhrase ? searchPhrase : ''));
    }, [searchPhrase]);

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const searchUrl = prepareSearchPhraseUrl(currentSearchPhrase);
        navigate(searchUrl);
    };

    const handleOnChange = (value: string) => {
        const sortUrl = prepareSortCriteria(value);
        navigate(sortUrl);
    };

    return (
        <Container>
            <Form onSubmit={handleOnSubmit}>
                <SearchInput
                    type="text"
                    value={currentSearchPhrase || ''}
                    label="Szukaj"
                    onChange={setCurrentSearchPhrase}
                />
                <SearchButton type="submit">
                    <Ripple />
                    <CustomSearchIcon />
                </SearchButton>
            </Form>
            <Select label="Sortowanie" value={sortCriteria} onChange={handleOnChange}>
                <option value={CREATED_AT_DESC}>Od najnowszych</option>
                <option value={CREATED_AT_ASC}>Od najstarszych</option>
                <option value={PRICE_ASC}>Cena, rosnąco</option>
                <option value={PRICE_DES}>Cena, malejąco</option>
            </Select>
        </Container>
    );
};

export default SearchAndSort;
