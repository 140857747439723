import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/theme';
import { Subcategory } from '../../generated/graphql';
import SubcategoryItem from './subcategory-item';
import { Link } from 'gatsby';

interface CategoryContainerProps extends ThemedProps {
    selected: boolean;
}

const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

const CategoryName = styled.h2<CategoryContainerProps>`
    margin: 0;
    text-transform: uppercase;
    font-size: ${({ theme }: CategoryContainerProps) => theme.typography.sizes.normal};
    font-weight: ${({ selected, theme }: CategoryContainerProps) =>
        selected ? theme.typography.weights.bold : theme.typography.weights.normal};
`;

const CategoryLink = styled(Link)<ThemedProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }: ThemedProps) => theme.colors.black};

    &:hover {
        ${CategoryName} {
            color: ${({ theme }: ThemedProps) => theme.colors.darkgray} !important;
        }
    }
`;

const SubcategoriesList = styled.ul<CategoryContainerProps>`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    height: ${({ selected }: CategoryContainerProps) => (selected ? 'auto' : '0px')};

    & > * {
        margin: 0.25rem 0;
    }

    ${({ theme }: CategoryContainerProps) => theme.mediaQueries.mobile} {
        height: auto;
    }
`;

interface Props {
    selected: boolean;
    categoryId: string;
    name: string;
    subcategories: Subcategory[];
    selectedSubcategoryId?: string;
    prepareCategoryUrl: (id: string) => string;
    prepareSubcategoryUrl: (subcategoryId: string, categoryId: string) => string;
    handleClose: () => void;
}

const CategoryItem: FunctionComponent<Props> = ({
    selected,
    categoryId,
    name,
    subcategories,
    selectedSubcategoryId,
    prepareCategoryUrl,
    prepareSubcategoryUrl,
    handleClose,
}) => {
    const resolveIfSubcategorySelected = (subcategoryId: string) => subcategoryId === selectedSubcategoryId;

    const categoryUrl = prepareCategoryUrl(categoryId);

    return (
        <CategoryContainer>
            <CategoryLink to={categoryUrl} onClick={handleClose}>
                <CategoryName selected={selected}>{name}</CategoryName>
            </CategoryLink>
            <SubcategoriesList selected={selected}>
                {subcategories.map((subcategory) => (
                    <li key={subcategory.id}>
                        <SubcategoryItem
                            selected={resolveIfSubcategorySelected(subcategory.id)}
                            categoryId={categoryId}
                            subcategoryId={subcategory.id}
                            name={subcategory.name}
                            prepareSubcategoryUrl={prepareSubcategoryUrl}
                            handleClose={handleClose}
                        />
                    </li>
                ))}
            </SubcategoriesList>
        </CategoryContainer>
    );
};

export default CategoryItem;
