import React from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';
import { FunctionComponent } from 'react';

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 2rem;
`;

const Spinner = styled.div<ThemedProps>`
    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    animation: rotating 2s linear infinite;
    width: 5rem;
    height: 5rem;
    position: relative;

    &::after,
    &::before {
        position: absolute;
        content: '';
        width: 1rem;
        height: 5rem;
        top: 0;
        left: calc(50% - 0.5rem);
        background-color: ${({ theme }: ThemedProps) => theme.colors.yellow};
    }

    &::after {
        transform: rotate(-45deg);
    }

    &::before {
        transform: rotate(45deg);
    }
`;

const Loader: FunctionComponent = () => (
    <SpinnerContainer>
        <Spinner />
    </SpinnerContainer>
);

export default Loader;
