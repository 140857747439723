import React, { FunctionComponent, useEffect, useState } from 'react';
import LayoutWithProtection from '../../components/layout/layout-with-protection';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import Products from '../../components/product/products';
import styled from 'styled-components';
import { useProductsQuery } from '../../generated/graphql';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { parse } from 'query-string';
import SearchAndSort from '../../components/search-and-sort';
import { ThemedProps } from '../../styles/theme';
import Paginator from '../../components/paginator';
import fetch from 'cross-fetch';
import { CATALOG_BASE_URL, QUERY_VARIABLES_KEYS, SORT_CRITERIA_VALUES } from '../../common';
import CategoriesMenu from '../../components/categories-menu/categories-menu';
import FiltersMenu from '../../components/filters-menu/filters-menu';
import PageContainer from '../../components/page-container';
import AdvertisesBar from '../../components/advertises-bar';

const CatalogContainer = styled(PageContainer)<ThemedProps>`
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template: min-content / repeat(10, 1fr);
`;

const AdvertisesBarContainer = styled.aside`
    grid-column: 1 / -1;
`;

const AsideMenuContainer = styled.aside`
    grid-column: 1 / 4;
    display: grid;
    gap: 1rem;
    grid-auto-rows: min-content;
    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        grid-column: 1 / -1;
    }
`;

const ProductsContainer = styled.section`
    grid-column: 4 / 11;
    display: grid;
    grid-auto-rows: min-content;
    gap: 1rem;
    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        grid-column: 1 / -1;
    }
`;

export type QueryVariable = string | string[] | null | undefined;

export const query = graphql`
    query {
        cms {
            categories {
                id
                name
                subcategories {
                    id
                    name
                }
            }
            advertises {
                id
                url
                image {
                    url
                }
            }
        }
    }
`;

const client = new ApolloClient({
    link: createHttpLink({
        uri: process.env.GATSBY_API_URL,
        fetch: fetch,
        headers: {
            authorization: process.env.GATSBY_API_TOKEN || '',
        },
    }),
    cache: new InMemoryCache(),
});

const buildProductsQueryVariables: any = (
    categoryId: QueryVariable,
    subcategoryId: QueryVariable,
    searchPhrase: QueryVariable,
    sortCriteria: QueryVariable,
    currentPageNumber: QueryVariable,
    productsPerPageNumber: number,
    sale: QueryVariable,
    latest_: QueryVariable,
) => {
    const subcategory = {
        id: subcategoryId,
        category: {
            id: categoryId,
        },
    };

    const name_contains = searchPhrase;
    const orderBy = sortCriteria || SORT_CRITERIA_VALUES.CREATED_AT_DESC;

    const skip = currentPageNumber ? (Number(currentPageNumber) - 1) * productsPerPageNumber : undefined;
    const first = productsPerPageNumber;

    const oldPrice_gte = sale ? 0 : undefined;
    const latest = latest_ ? true : undefined;

    return {
        where: {
            name_contains,
            subcategory,
            oldPrice_gte,
            latest,
        },
        orderBy,
        skip,
        first,
    };
};

const resolvePagesNumber = (totalItemsNumber: number, itemsPerPageNumber: number): number => {
    return Math.ceil(totalItemsNumber / itemsPerPageNumber);
};

const resolveUrl = (newUrlQueryVariables: string[]) => {
    return newUrlQueryVariables.length > 0
        ? `${CATALOG_BASE_URL}?${newUrlQueryVariables.join('&')}`
        : `${CATALOG_BASE_URL}`;
};

const resolveNewUrlQueryVariables = (
    categoryId: QueryVariable,
    subcategoryId: QueryVariable,
    currentPageNumber: QueryVariable,
    searchPhrase: QueryVariable,
    sortCriteria: QueryVariable,
    sale: QueryVariable,
    latest: QueryVariable,
) => {
    const newUrlQueryVariables: string[] = [];

    categoryId && newUrlQueryVariables.push(`${CATEGORY_ID_KEY}=${categoryId}`);
    categoryId && subcategoryId && newUrlQueryVariables.push(`${SUBCATEGORY_ID_KEY}=${subcategoryId}`);
    currentPageNumber && newUrlQueryVariables.push(`${CURRENT_PAGE_NUMBER_KEY}=${currentPageNumber}`);
    searchPhrase && newUrlQueryVariables.push(`${SEARCH_PHRASE_KEY}=${searchPhrase}`);
    sortCriteria && newUrlQueryVariables.push(`${SORT_CRITERIA_KEY}=${sortCriteria}`);
    sale && newUrlQueryVariables.push(`${SALE_KEY}=${sale}`);
    latest && newUrlQueryVariables.push(`${LATEST_KEY}=${latest}`);

    return resolveUrl(newUrlQueryVariables);
};

const returnUndefinedIfTheSame = (previous: any, current: any): any | undefined =>
    previous === current ? undefined : current;

const {
    CATEGORY_ID_KEY,
    SUBCATEGORY_ID_KEY,
    CURRENT_PAGE_NUMBER_KEY,
    SEARCH_PHRASE_KEY,
    SORT_CRITERIA_KEY,
    SALE_KEY,
    LATEST_KEY,
} = QUERY_VARIABLES_KEYS;

const Catalog: FunctionComponent<any> = ({ data: { cms } }) => {
    const location = useLocation();
    const [categoryId, setCategoryId] = useState<QueryVariable>(undefined);
    const [subcategoryId, setSubcategoryId] = useState<QueryVariable>(undefined);
    const [currentPageNumber, setCurrentPageNumber] = useState<QueryVariable>(undefined);
    const [searchPhrase, setSearchPhrase] = useState<QueryVariable>(undefined);
    const [sortCriteria, setSortCriteria] = useState<QueryVariable>(undefined);
    const [sale, setSale] = useState<QueryVariable>(undefined);
    const [latest, setLatest] = useState<QueryVariable>(undefined);

    useEffect(() => {
        const { categoryId, subcategoryId, currentPageNumber, searchPhrase, sortCriteria, sale, latest } = parse(
            location.search,
        );

        setCategoryId(categoryId);
        setSubcategoryId(subcategoryId);
        setCurrentPageNumber(currentPageNumber);
        setSearchPhrase(searchPhrase);
        setSortCriteria(sortCriteria);
        setSale(sale);
        setLatest(latest);
    });

    const [productsPerPage] = useState<number>(12);

    const prepareCategoryUrl = (selectedCategoryId: string | undefined) =>
        resolveNewUrlQueryVariables(
            returnUndefinedIfTheSame(categoryId, selectedCategoryId),
            undefined,
            undefined,
            '',
            sortCriteria,
            sale,
            latest,
        );

    const prepareSubcategoryUrl = (selectedSubcategoryId: string | undefined, selectedCategoryId: string | undefined) =>
        resolveNewUrlQueryVariables(
            selectedCategoryId,
            returnUndefinedIfTheSame(subcategoryId, selectedSubcategoryId),
            undefined,
            '',
            sortCriteria,
            sale,
            latest,
        );

    const prepareCurrentPageUrl = (selectedPageNumber: string | undefined) =>
        resolveNewUrlQueryVariables(
            categoryId,
            subcategoryId,
            selectedPageNumber,
            searchPhrase,
            sortCriteria,
            sale,
            latest,
        );

    const prepareSearchPhraseUrl = (selectedSearchPhrase: string | undefined) =>
        resolveNewUrlQueryVariables(
            categoryId,
            subcategoryId,
            currentPageNumber,
            selectedSearchPhrase,
            sortCriteria,
            sale,
            latest,
        );

    const prepareSortCriteriaUrl = (selectedSortCriteria: string | undefined) =>
        resolveNewUrlQueryVariables(
            categoryId,
            subcategoryId,
            currentPageNumber,
            searchPhrase,
            selectedSortCriteria,
            sale,
            latest,
        );

    const prepareSaleUrl = (isSale: boolean) =>
        resolveNewUrlQueryVariables(
            categoryId,
            subcategoryId,
            undefined,
            searchPhrase,
            sortCriteria,
            isSale ? 'true' : undefined,
            latest,
        );

    const prepareLatestUrl = (isLatest: boolean) =>
        resolveNewUrlQueryVariables(
            categoryId,
            subcategoryId,
            undefined,
            searchPhrase,
            sortCriteria,
            sale,
            isLatest ? 'true' : undefined,
        );


    const { loading, error, data } = useProductsQuery({
        client,
        variables: buildProductsQueryVariables(
            categoryId,
            subcategoryId,
            searchPhrase,
            sortCriteria,
            currentPageNumber,
            productsPerPage,
            sale,
            latest,
        ),
    });

    return (
        <LayoutWithProtection>
            <CatalogContainer>
                <AdvertisesBarContainer>
                    <AdvertisesBar advertises={cms.advertises} />
                </AdvertisesBarContainer>
                <AsideMenuContainer>
                    <CategoriesMenu
                        categories={cms.categories}
                        selectedCategoryId={categoryId?.toString()}
                        selectedSubcategoryId={subcategoryId?.toString()}
                        prepareCategoryUrl={prepareCategoryUrl}
                        prepareSubcategoryUrl={prepareSubcategoryUrl}
                    />
                    <FiltersMenu
                        isSaleChecked={!!sale}
                        isLatestChecked={!!latest}
                        prepareSaleUrl={prepareSaleUrl}
                        prepareLatestUrl={prepareLatestUrl}

                    />
                </AsideMenuContainer>
                <ProductsContainer>
                    <SearchAndSort
                        searchPhrase={searchPhrase?.toString()}
                        sortCriteria={sortCriteria?.toString()}
                        prepareSearchPhraseUrl={prepareSearchPhraseUrl}
                        prepareSortCriteria={prepareSortCriteriaUrl}
                    />
                    <Products data={data} loading={loading} error={error} />
                    <Paginator
                        currentPageNumber={Number(currentPageNumber || 1)}
                        prepareCurrentPageUrl={prepareCurrentPageUrl}
                        totalPagesNumber={resolvePagesNumber(data?.allItems.length || 1, productsPerPage)}
                    />
                </ProductsContainer>
            </CatalogContainer>
        </LayoutWithProtection>
    );
};

export default Catalog;
