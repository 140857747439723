import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/theme';
import { FunctionComponent } from 'react';
import { Category } from '../../generated/graphql';
import CategoryItem from '../categories-menu/category-item';
import AsideMenu from '../aside-menu';
import CategoriesMenuIcon from '../../static/icons/icon-categories.inline.svg';
import { lockGlobalScroll } from '../../utils/utils';

const Categories = styled.div<ThemedProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > * {
        margin: 0.25rem 0;
    }
`;

const CategoriesList = styled.ul<ThemedProps>`
    display: flex;
    flex-direction: column;
    & > * {
        margin: 0.25rem 0;
    }
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
`;

const CategoriesTitle = styled.h2<ThemedProps>`
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.normal};
    font-weight: ${({ theme }: ThemedProps) => theme.typography.weights.bold};
    margin: 0;
`;

const CustomCategoriesMenuIcon = styled(CategoriesMenuIcon)`
    width: 1rem;
    height: 1rem;
`;

interface Props {
    categories: Category[];
    selectedCategoryId?: string;
    selectedSubcategoryId?: string;
    prepareCategoryUrl: (selectedCategoryId: string) => string;
    prepareSubcategoryUrl: (selectedSubcategoryId: string, selectedCategoryId: string) => string;
}

const CategoriesMenu: FunctionComponent<Props> = ({
    categories,
    selectedCategoryId,
    selectedSubcategoryId,
    prepareCategoryUrl,
    prepareSubcategoryUrl,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(() => {
            lockGlobalScroll(false);
            return false;
        });
    };

    const handleSetIsOpen = () =>
        setIsOpen((state) => {
            lockGlobalScroll(!state);
            return !state;
        });

    const resolveIfCategorySelected = (categoryId: string) => categoryId === selectedCategoryId;

    return (
        <AsideMenu
            isOpen={isOpen}
            handleSetIsOpen={handleSetIsOpen}
            openingButtonTitle="Kategorie"
            closingButtonTitle="Zamknij"
            openingButtonIcon={<CustomCategoriesMenuIcon />}
        >
            <Categories>
                <CategoriesTitle>Kategorie</CategoriesTitle>
                <CategoriesList>
                    {categories.map((category) => (
                        <li key={category.id}>
                            <CategoryItem
                                categoryId={category.id}
                                name={category.name}
                                subcategories={category.subcategories}
                                selectedSubcategoryId={selectedSubcategoryId}
                                selected={resolveIfCategorySelected(category.id)}
                                prepareCategoryUrl={prepareCategoryUrl}
                                prepareSubcategoryUrl={prepareSubcategoryUrl}
                                handleClose={handleClose}
                            />
                        </li>
                    ))}
                </CategoriesList>
            </Categories>
        </AsideMenu>
    );
};

export default CategoriesMenu;
