import React, { FunctionComponent } from 'react';
import { ProductsQuery } from '../../generated/graphql';
import { ApolloError } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import Loader from '../loader';
import ProductCard from './product-card';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import State from '../state';
import { ThemedProps } from '../../styles/theme';
import { PRODUCT_BASE_URL } from '../../common';

const ProductsCardsContainer = styled.div<ThemedProps>`
    display: grid;
    gap: 1rem;

    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(300px, 1fr);

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: minmax(350px, 1fr);
    }
`;

interface Props extends RouteComponentProps {
    data: ProductsQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}

const Products: FunctionComponent<Props> = ({ data, loading, error }) => {
    const images = useStaticQuery(graphql`
        query {
            errorStateIllustration: file(relativePath: { eq: "error-state.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            emptyStateIllustration: file(relativePath: { eq: "empty-state.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    if (error)
        return (
            <State
                messageTitle="Wystąpił nieoczekiwany błąd!"
                message={error.message}
                imageAlt="error-state"
                imageFluid={images.errorStateIllustration.childImageSharp.fluid}
            />
        );

    if (loading) return <Loader />;

    if (!data || data.products.length == 0)
        return (
            <State
                messageTitle="Nie znaleziono produktów!"
                imageAlt="error-state"
                imageFluid={images.emptyStateIllustration.childImageSharp.fluid}
            />
        );

    return (
        <ProductsCardsContainer>
            {data.products.map((product) => (
                <Link key={product.id} to={`${PRODUCT_BASE_URL}/${product.id}`}>
                    <ProductCard
                        name={product.name}
                        latest={product.latest}
                        createdAt={new Date(product.createdAt)}
                        imageUrl={product.mainImage.url}
                        price={product.price}
                        oldPrice={product.oldPrice}
                    />
                </Link>
            ))}
        </ProductsCardsContainer>
    );
};

export default Products;
